import React from "react";
import { Col } from "reactstrap";
import Solicitud from "./Solicitud";
import Adjuntos from "./Adjuntos";
import InformacionDelivery from "./InformacionDelivery";
import {  LoadScript } from "@react-google-maps/api";


const TicketDetails = ({ solicitud }) => {  
  return (
    <React.Fragment>
      <Col xxl={3}>
        <Solicitud solicitud={solicitud} />
        <Adjuntos solicitud={solicitud} />

        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_APIKEYGOOGLEMAPS}
          libraries={["places"]}
          language="es"
          region="ve"
          onLoad={() => console.log("Google Maps Script cargado")}
          onError={(error) => console.error("Error cargando Google Maps:", error)}
        >
          <InformacionDelivery solicitud={solicitud} />
        </LoadScript>
      </Col>
    </React.Fragment>
  );
};

export default TicketDetails;
