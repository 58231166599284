import React from "react";
import { Container, Row, Col, Card, CardBody,Button, CardHeader } from "reactstrap";
import { ListaSolicitudes } from "./ListaSolicitudes";

const ListView = () => {
  document.title = "Lista de Solicitudes | Farme";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Solicitudes</h5>
                </CardHeader>
                <CardBody>
                  <ListaSolicitudes />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ListView;
