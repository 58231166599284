import React, { useMemo, useState, useEffect } from "react";
import { Alert } from "reactstrap";
import {
  getLabs,
  deleteLab,
  resetLaboratorioFlag,
} from "../../../slices/thunks";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
//import { laboratorios } from "../../../common/data";
import TableContainer from "../../../Components/Common/TableContainer";
import DeleteModal from "../../../Components/Common/DeleteModal";

const ListaLaboratorio = () => {
  console.log("ListaLaboratorio rendered");
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state;

  const laboratorioPageData = createSelector(selectLayoutState, (state) => ({
    laboratorios: state.Laboratorios.laboratorios,
    error: state.Laboratorios.error,
    loading: state.Laboratorios.loading,
    errorMsg: state.Laboratorios.errorMsg,
    colorMsg: state.Laboratorios.colorMsg,
  }));

  // Inside your component
  const { laboratorios, error, loading, errorMsg, colorMsg } =
    useSelector(laboratorioPageData);

  console.log("laboratorios:", laboratorios);
  console.log("error:", error);
  console.log("loading:", loading);
  console.log("errorMsg:", errorMsg);
  console.log("colorMsg:", colorMsg);

  useEffect(() => {
    if (errorMsg) {
      console.log("useEffect errorMsg is true");
      setTimeout(() => {
        dispatch(resetLaboratorioFlag());
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    console.log("useEffect loading is true");
    dispatch(getLabs());
  }, [dispatch, loading]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [borrarSolicitud, setBorrarSolicitud] = useState({});

  const onClickDelete = (borrar) => {
    if (borrar) {
      console.log("Deleting lab with id:", borrarSolicitud.id);
      dispatch(deleteLab(borrarSolicitud.id));
    }

    setDeleteModal(false);
  };

  const solicitudDelete = (solicitud) => {
    setBorrarSolicitud(solicitud);
    setDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        header: "Laboratorio",
        accessorKey: "nombre",
        enableColumnFilter: false,
      },
      {
        header: "Origen",
        accessorKey: "origen",
        enableColumnFilter: false,
      },
      {
        header: "Transnacional",
        cell: (cell) => {
          return <>{cell.getValue() ? "Si" : "No"}</>;
        },
        accessorKey: "transnacional",
        enableColumnFilter: false,
      },
      {
        header: "Calidad Farmacológica",
        accessorKey: "calidadFarmacologica",
        enableColumnFilter: false,
      },
      {
        header: "Calidad Presentación",
        accessorKey: "calificacionImagen",
        enableColumnFilter: false,
      },

      {
        header: "Estatus",
        cell: (cell) => {
          return (
            <React.Fragment>
              {cell.getValue() ? (
                <span className="badge bg-success-subtle text-success text-uppercase">
                  Activo
                </span>
              ) : (
                <span className="badge bg-danger-subtle text-danger text-uppercase">
                  {" "}
                  No Activo{" "}
                </span>
              )}
            </React.Fragment>
          );
        },

        accessorKey: "activo",
        enableColumnFilter: false,
      },

      {
        header: "Acción",
        accessorKey: "accion",
        enableColumnFilter: false,
        cell: (cell) => {
          const rowId = cell.row.original.id;
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/laboratorios/editar/${rowId}/`}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-28" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Editar
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  console.log("Solicitud delete:", cell.row.original);
                  const orderData = cell.row.original;
                  solicitudDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />

                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Eliminar
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {errorMsg && errorMsg ? <Alert color={colorMsg}> {error} </Alert> : null}

      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={() => {
          console.log("Deleting lab with id:", borrarSolicitud.id);
          onClickDelete(true);
        }}
      />

      <TableContainer
        columns={columns || []}
        data={laboratorios[0] || []}
        isGlobalFilter={true}
        customPageSize={10}
        SearchPlaceholder="Buscar..."
        divClass="table-responsive"
      />
    </React.Fragment>
  );
};

export { ListaLaboratorio };
