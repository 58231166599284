import React, { useMemo, useState, useEffect } from "react";
import { Alert } from "reactstrap";
import {
  getSolicitudes,
  deleteSolicitud,
  resetSolicitudFlag,
} from "../../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";

import TableContainer from "../../../Components/Common/TableContainer";
import { PorcentajeAvance } from "./ColSolicitudes";

const ListaSolicitudes = () => {
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state;

  const pageData = createSelector(selectLayoutState, (state) => ({
    solicitudes: state.Solicitudes.solicitudes,
    error: state.Solicitudes.error,
    loading: state.Solicitudes.loading,
    errorMsg: state.Solicitudes.errorMsg,
    colorMsg: state.Solicitudes.colorMsg,
    exito: state.Solicitudes.exito,
  }));

  // Inside your component
  const { solicitudes, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  console.log("solicitudes:", solicitudes);
  console.log("error:", error);
  console.log("loading:", loading);
  console.log("errorMsg:", errorMsg);
  console.log("colorMsg:", colorMsg);
  console.log("exito:", exito);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetSolicitudFlag());
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    dispatch(getSolicitudes("query=Nueva%20Solicitud"));
  }, [dispatch, loading]);

  const columns = useMemo(
    () => [
      {
        header: "ID",
        cell: (cell) => {
          return <span className="fw-semibold">{cell.getValue()}</span>;
        },
        accessorKey: "identificador",
        enableColumnFilter: false,
      },

      {
        header: "Detalle",
        accessorKey: "detalle",
        enableColumnFilter: false,
      },

      {
        header: "Solicitante",
        accessorKey: "solicitante",
        enableColumnFilter: false,
      },
      {
        header: "Tipo de Solicitud",
        accessorKey: "tipo",
        enableColumnFilter: false,
      },
      {
        header: "Fecha de Solicitud",
        accessorKey: "creado",
        enableColumnFilter: false,
      },
      {
        header: "Porcentaje de Avance",
        accessorKey: "porcentaje_avance",
        enableColumnFilter: false,
        cell: (cell) => {
          return <PorcentajeAvance {...cell} />;
        },
      },
      {
        header: "Acción",
        accessorKey: "accion",
        enableColumnFilter: false,
        cell: (cell) => {
          const rowId = cell.row.original.id;
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/solicitud/ver/${rowId}`}
                className="text-primary d-inline-block"
              >
                <i className="ri-eye-fill fs-16"></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <TableContainer
        columns={columns || []}
        data={solicitudes[0] || []}
        isGlobalFilter={true}
        customPageSize={5}
        SearchPlaceholder="Buscar..."
        divClass="table-responsive"
      />
    </React.Fragment>
  );
};

export { ListaSolicitudes };
