import React, { useMemo, useState } from "react";
import { Col, Collapse, Row, Form } from "reactstrap";
import classnames from "classnames";
import TableContainer from "../../Components/Common/TableContainerReactTable";
import { FormularioDireccionEntrega } from "./FormularioDireccionEntrega";
import Select from "react-select";

const EnfermedadesCronicas = () => {
  const [activeTab, setactiveTab] = useState(1);
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  function toggleTab(tab, value) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }
  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
    setcol4(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
    setcol4(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
    setcol4(false);
  };

  const t_col4 = () => {
    setcol4(!col4);
    setcol1(false);
    setcol2(false);
    setcol3(false);
  };
  const [selectedSingle, setSelectedSingle] = useState({
    value: "2",
    label: "Oficina",
  });

  const SingleOptions = [
    { value: "1", label: "Casa" },
    { value: "2", label: "Oficina" },
  ];
  function handleSelectSingle(selectedSingle) {
    setSelectedSingle(selectedSingle);
  }

  const datosSolicitudes = [
    {
      principioActivo: "Acetaminofen - 500mg - Tabletas",
      tratamiento: "3",
      dosis: "2",
    },
    {
      principioActivo: "Hereda Helix - 5ml - Jarabe",
      tratamiento: "3",
      dosis: "2",
    },
    {
      principioActivo: "Ibuprofeno - 600mg - Tabletas",
      tratamiento: "3",
      dosis: "2",
    },
    {
      principioActivo: "Paracetamol - 500mg - Tabletas",
      tratamiento: "3",
      dosis: "2",
    },
    {
      principioActivo:
        "Bromuro de Pinaverio / Simeticona - 100mg/300mg - Tabletas",
      tratamiento: "3",
      dosis: "2",
    },
    {
      principioActivo: "Acetaminofen - 500mg - Tabletas",
      tratamiento: "3",
      dosis: "2",
    },
    {
      principioActivo:
        "Bromuro de Pinaverio / Simeticona - 100mg/300mg - Tabletas",
      tratamiento: "3",
      dosis: "2",
    },
    {
      principioActivo: "Acetaminofen - 500mg - Tabletas",
      tratamiento: "3",
      dosis: "2",
    },
    {
      principioActivo:
        "Bromuro de Pinaverio / Simeticona - 100mg/300mg - Tabletas",
      tratamiento: "3",
      dosis: "2",
    },
    {
      principioActivo: "Paracetamol - 500mg - Tabletas",
      tratamiento: "3",
      dosis: "2",
    },
    {
      principioActivo: "Paracetamol - 500mg - Tabletas",
      tratamiento: "3",
      dosis: "2",
    },
    {
      principioActivo: "Acetaminofen - 500mg - Tabletas",
      tratamiento: "3",
      dosis: "2",
    },
    {
      principioActivo:
        "Bromuro de Pinaverio / Simeticona - 100mg/300mg - Tabletas",
      tratamiento: "3",
      dosis: "2",
    },
    {
      principioActivo: "Acetaminofen - 500mg - Tabletas",
      tratamiento: "3",
      dosis: "2",
    },
  ];

  const columns = useMemo(
    () => [
      {
        header: "Principio activo",
        accessorKey: "principioActivo",
        enableColumnFilter: false,
      },
      {
        header: "Tratamiento",
        accessorKey: "tratamiento",
        enableColumnFilter: false,
      },
      {
        header: "Dosis",
        accessorKey: "dosis",
        enableColumnFilter: false,
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <div className="mt-3">
            <div
              className="accordion accordion-border-box"
              id="genques-accordion"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col1,
                    })}
                    type="button"
                    onClick={t_col1}
                    style={{ cursor: "pointer" }}
                  >
                    Ronaldo Rivero - C.I: 25.123.456 - Cardiopatía
                  </button>
                </h2>

                <Collapse isOpen={col1} className="accordion-collapse">
                  <TableContainer
                    columns={columns || []}
                    data={datosSolicitudes || []}
                    isGlobalFilter={true}
                    customPageSize={5}
                    SearchPlaceholder="Buscar..."
                    divClass="table-responsive p-3"
                  />
                  <FormularioDireccionEntrega />
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingTwo">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col2,
                    })}
                    type="button"
                    onClick={t_col2}
                    style={{ cursor: "pointer" }}
                  >
                    Oscar Velasquez - C.I: 10.234.456 - Diabetes
                  </button>
                </h2>
                <Collapse isOpen={col2} className="accordion-collapse">
                  <TableContainer
                    columns={columns || []}
                    data={datosSolicitudes || []}
                    isGlobalFilter={true}
                    customPageSize={5}
                    SearchPlaceholder="Buscar..."
                    divClass="table-responsive"
                  />

                  <FormularioDireccionEntrega />
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingThree">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col3,
                    })}
                    type="button"
                    onClick={t_col3}
                    style={{ cursor: "pointer" }}
                  >
                    Ronaldo Rivero - C.I: 25.123.456 - Artritis
                  </button>
                </h2>
                <Collapse isOpen={col3} className="accordion-collapse">
                  <TableContainer
                    columns={columns || []}
                    data={datosSolicitudes || []}
                    isGlobalFilter={true}
                    customPageSize={5}
                    SearchPlaceholder="Buscar..."
                    divClass="table-responsive"
                  />

                  <FormularioDireccionEntrega />
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="genques-headingFour">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col4,
                    })}
                    type="button"
                    onClick={t_col4}
                    style={{ cursor: "pointer" }}
                  >
                    Oscar Velasquez - C.I: 10.234.456 - Alzheimer
                  </button>
                </h2>
                <Collapse isOpen={col4} className="accordion-collapse">
                  <TableContainer
                    columns={columns || []}
                    data={datosSolicitudes || []}
                    isGlobalFilter={true}
                    customPageSize={5}
                    SearchPlaceholder="Buscar..."
                    divClass="table-responsive"
                  />

                  <FormularioDireccionEntrega />
                </Collapse>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export { EnfermedadesCronicas };
