import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  laboratorios: [],
  laboratorio: {},
  error: "", // for error message
  loading: false,
  errorMsg: false, // for error
  colorMsg: "",
  exito: false,
};

const laboratoriosSlice = createSlice({
  name: "laboratorios",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "danger";
    },
    laboratoriosSuccess(state, action) {
      state.laboratorios = action.payload.data;
      state.loading = false;
      state.errorMsg = false;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    laboratorioSuccess(state, action) {
      state.laboratorio = action.payload.data;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "success";
      state.error = action.payload.message;
      state.exito = true;
    },
    laboratoriosDeleteSuccess(state, action) {
      state.error = action.payload.message;
      state.loading = true;
      state.errorMsg = true;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    reset_laboratorio_flag(state) {
      state.success = false;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
      state.colorMsg = "";
    },
  },
});

export const {
  apiError,
  laboratoriosSuccess,
  laboratorioSuccess,
  laboratoriosDeleteSuccess,
  reset_laboratorio_flag,
} = laboratoriosSlice.actions;

export default laboratoriosSlice.reducer;
