import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  usuarios: [],
  usuario: {},	
  error: "", // for error message
  loading: false,
  errorMsg: false, // for error
  colorMsg: "",
  exito: false,
};

const usuariosSlice = createSlice({
  name: "usuarios",
  initialState,
  reducers: {

    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "danger";
    },
    usuariosSuccess(state, action) {
      state.usuarios = action.payload.data;
      state.loading = false;
      state.errorMsg = false;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    usuarioSuccess(state, action) {
      state.usuario = action.payload.data;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "success";
      state.error = action.payload.message;
      state.exito = true;
    },
    usuariosDeleteSuccess(state, action) {
      state.error = action.payload.message;
      state.loading = true;
      state.errorMsg = true;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    reset_usuario_flag(state) {
      state.success = false;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
      state.colorMsg = "";
    },
  },
});

export const {
  apiError,
  usuariosSuccess,
  usuarioSuccess,
  usuariosDeleteSuccess,
  reset_usuario_flag,
} = usuariosSlice.actions;

export default usuariosSlice.reducer;
