import React from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import CrearPrincipioActivo from "./CrearPrincipioActivo";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const PrincipioActivoCrear = () => {
  document.title = "Crear Principio Activo | Farme";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <BreadCrumb
            title="Crear Principio Activo"
            pageTitle="Listar Principios Activos"
            url="/principiosactivos"
          />

        
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Principios Activos</h5>
                </CardHeader>
                <CardBody>

                  <CrearPrincipioActivo />

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrincipioActivoCrear;
