import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  planillas: [],
  planilla: {},
  error: "", // for error message
  loading: false,
  errorMsg: false, // for error
  colorMsg: "",
  exito: false,
};

const planillaestrategicaSlice = createSlice({
  name: "planillaestrategica",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "danger";
    },
    planillasSuccess(state, action) {
      state.planillas = action.payload.data;
      state.loading = false;
      state.errorMsg = false;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    planillaSuccess(state, action) {
      state.planilla = action.payload.data;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "success";
      state.error = action.payload.message;
      state.exito = true;
    },
    planillaDeleteSuccess(state, action) {
      state.error = action.payload.message;
      state.loading = true;
      state.errorMsg = true;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    reset_planilla_flag(state) {
      state.success = false;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
      state.colorMsg = "";
    },
  },
});

export const {
  apiError, planillasSuccess, planillaSuccess, planillaDeleteSuccess, reset_planilla_flag
} = planillaestrategicaSlice.actions;

export default planillaestrategicaSlice.reducer;
