import React, { useMemo } from "react";
import TableContainer from "../../../Components/Common/TableContainerReactTable";

const ListaFamiliares = () => {


  const datosSolicitudes = [
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Daniel",
      apellido: "Perez",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Hermano"
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Leonel",
      apellido: "Messi",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Hijo"
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Ronaldo",
      apellido: "CR7",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Padre"
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Valentina",
      apellido: "Bricenco",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Hermana"
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Liset",
      apellido: "Martinez",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Abuela"
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Yeiver",
      apellido: "Valera",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Primo"
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Marcos",
      apellido: "Angulo",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Tio"
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Roberto",
      apellido: "Garmendia",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Primo"
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Carlos",
      apellido: "Guerra",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Abuelo",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Juan",
      apellido: "Trucupei",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Hermano",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Armando",
      apellido: "Guerra",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Primo",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Armando",
      apellido: "Guerra",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Primo",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Armando",
      apellido: "Guerra",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Primo",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Grecia",
      apellido: "Teran",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Prima",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Damian",
      apellido: "Suarez",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Primo",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Juana",
      apellido: "Hernandez",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Abuela",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Brando",
      apellido: "Martinez",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Hermano",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Jose",
      apellido: "Rondon",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Padre",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Ariana",
      apellido: "Lopez",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Hermana",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Diana",
      apellido: "Rivas",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Madre",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Daniela",
      apellido: "Perez",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Prima",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Jesus",
      apellido: "Teran",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Primo",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Ramon",
      apellido: "Torres",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Tio",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Pedro",
      apellido: "Garmendia",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Hermano",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Gabriel",
      apellido: "Guzman",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Primo",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Juan",
      apellido: "Guerrero",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Hermano",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Daniel",
      apellido: "Guevara",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Primo",
    },
    {
      
      nacionalidad: "Venezolano",
      cedula: "25123456",
      nombre: "Carmelo",
      apellido: "Lames",
      fechaNacimiento: "10-10-2020",
      correo: "j9NnK@example.com",
      telefono: "04161234567",
      numeroLocal: 8,
      parentesco: "Tio"      
    },
  ];

  const columns = useMemo(
    () => [

      {
        header: "Nacionalidad",
        accessorKey: "nacionalidad",
        enableColumnFilter: false,
      },
      {
        header: "Cédula",
        accessorKey: "cedula",
        enableColumnFilter: false,
      },
      {
        header: "Nombre",
        accessorKey: "nombre",
        enableColumnFilter: false,
      },
      {
        header: "Apellido",
        accessorKey: "apellido",
        enableColumnFilter: false,
      },
      {
        header: "Fecha de Nacimiento",
        accessorKey: "fechaNacimiento",
        enableColumnFilter: false,
      },
      {
        header: "Correo",
        accessorKey: "correo",
        enableColumnFilter: false,
      },
      {
        header: "Número de telefono",
        accessorKey: "telefono",
        enableColumnFilter: false,
      },
      {
        header: "Número de Local",
        accessorKey: "numeroLocal",
        enableColumnFilter: false,
      },
      {
        header: "Parentesco",
        accessorKey: "parentesco",
        enableColumnFilter: false,
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <TableContainer
        columns={columns || []}
        data={datosSolicitudes || []}
        isGlobalFilter={true}
        customPageSize={5}
        SearchPlaceholder="Buscar..."
        divClass="table-responsive"

      />
    </React.Fragment>
  );
};

export { ListaFamiliares };
