import React, { useState, useEffect } from "react";
import {
  getSolicitud,
  resetSolicitudFlag,
  getServicios,
  getUsers,
} from "../../../slices/thunks";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from "react-router-dom";

import { Container, Row } from "reactstrap";
import Section from "./Section";
import TicketDescription from "./TicketDescription";
import TicketDetails from "./TicketDetails";
import Comentarios from "./Comentarios";
import { medicamentos } from "../../../helpers/backend_helper";

const SolicitudDetalle = () => {
  document.title = "Detalle de la Solicitud | Farme";
  // Obtiene el ID desde la URL
  const { id } = useParams();
  ////console.log("id desde la URL:", id);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSolicitud(id));
    dispatch(getUsers());
  }, [dispatch, id]);

  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    solicitud: state.Solicitudes.solicitud,
    error: state.Solicitudes.error,
    loading: state.Solicitudes.loading,
    errorMsg: state.Solicitudes.errorMsg,
    colorMsg: state.Solicitudes.colorMsg,
    exito: state.Solicitudes.exito,
  }));

  // Inside your component
  const { solicitud, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);



  //OBTENER Servicios de Entrega
  const selectLayoutStateUser = (state) => state;
  const usuarioPageData = createSelector(selectLayoutStateUser, (state) => ({
    usuarios: state.Usuarios.usuarios
  }));

  // Inside your component
  const { usuarios } = useSelector(usuarioPageData);

  //console.log("usuarios:", usuarios);

  const [showdetails, setShowdetails] = useState(true);
  const [showResponsable, setShowResponsable] = useState(false);
  const [btnDely, setBtnDely] = useState(false);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Section solicitud={solicitud} />
          </Row>
          <Row>
            <TicketDescription
              solicitud={solicitud}
              showdetails={showdetails}
              setShowdetails={setShowdetails}
              setShowResponsable={setShowResponsable}
              btnDely={btnDely}
              setBtnDely={setBtnDely}
              usuarios={usuarios}
            />
            <TicketDetails
              solicitud={solicitud}
              
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SolicitudDetalle;
