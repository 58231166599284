import React, { useEffect, useState } from "react";
import {
  createPrincipioActivoCategoria,
  resetPrincipiosActivosCategoriaFlag,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  Input,
  Label,
  Form,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import ObjectId from "bson-objectid";
import { createSelector } from "reselect";
import { Navigate } from "react-router-dom";

/**
 * Crear
 *
 * @description Componente para crear un nuevo Principio Activo
 *
 * @returns {ReactElement} Componente Crear
 */
const Crear = () => {
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state;

  const principiosActivosPageData = createSelector(
    selectLayoutState,
    (state) => ({
      principioActivoCategoria:
        state.PrincipiosActivosCategorias.principioActivoCategoria,
      error: state.PrincipiosActivosCategorias.error,
      loading: state.PrincipiosActivosCategorias.loading,
      errorMsg: state.PrincipiosActivosCategorias.errorMsg,
      colorMsg: state.PrincipiosActivosCategorias.colorMsg,
      exito: state.PrincipiosActivosCategorias.exito,
    })
  );

  // Inside your component
  const {
    principioActivoCategoria,
    error,
    loading,
    errorMsg,
    colorMsg,
    exito,
  } = useSelector(principiosActivosPageData);

  console.log("principioActivoCategoria:", principioActivoCategoria);
  console.log("error:", error);
  console.log("loading:", loading);
  console.log("errorMsg:", errorMsg);
  console.log("colorMsg:", colorMsg);
  console.log("exito:", exito);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetPrincipiosActivosCategoriaFlag());
        setLoadingx(false);
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  const [departamento, setDepartamento] = useState("");
  const [categoria, setCategoria] = useState(null);
  const [subcategoria, setSubcategoria] = useState(null);

  const [redireccionar, setRedireccionar] = useState(false);
  const [loadingx, setLoadingx] = useState(false);

  const [modal_grid_composicion, setmodal_grid_composicion] = useState(false);

  function tog_grid_composicion() {
    setmodal_grid_composicion(!modal_grid_composicion);
  }

  useEffect(() => {
    if (exito) {
      setTimeout(() => {
        setRedireccionar(true);
      }, 2000);
    }
  }, [exito]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const create = {
      departamento: departamento,
      categoria: categoria,
      subcategoria: subcategoria,
    };

    dispatch(createPrincipioActivoCategoria(create));
    setLoadingx(true);
  };

  return (
    <>
      {redireccionar && <Navigate to="/principiosactivoscategorias" replace />}

      <React.Fragment>
        {errorMsg && errorMsg ? (
          <Alert color={colorMsg}> {error} </Alert>
        ) : null}

        <Form onSubmit={handleSubmit}>
          <Row lg={12} className="p-3">
            <Col xxl={4} md={4}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="gen-info-email-input">
                  Departamento <span className="text-danger">*</span>
                </Label>

                <Input
                  required
                  minLength={3}
                  maxLength={50}
                  type="text"
                  className="form-control"
                  id="departamento"
                  name="departamento"
                  value={departamento}
                  onChange={(e) => setDepartamento(e.target.value)}
                />
              </div>
            </Col>
            <Col xxl={4} md={4}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="activo">
                  Categoria <span className="text-danger">*</span>
                </Label>
                <Input
                  required
                  minLength={3}
                  maxLength={50}
                  type="text"
                  className="form-control"
                  id="categoria"
                  name="categoria"
                  value={categoria}
                  onChange={(e) => setCategoria(e.target.value)}
                />
              </div>
            </Col>

            <Col xxl={4} md={4}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="activo">
                  Sub. Categoria <span className="text-danger">*</span>
                </Label>
                <Input
                  required
                  minLength={3}
                  maxLength={50}
                  type="text"
                  className="form-control"
                  id="subcategoria"
                  name="subcategoria"
                  value={subcategoria}
                  onChange={(e) => setSubcategoria(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="align-items-end">
            <Col lg={12}>
              <div className="mt-4 mb-3 text-end">
                <button
                  type="submit"
                  className="btn btn-success btn-label right ms-auto nexttab nexttab"
                >
                  {loadingx ? (
                    <Spinner size="sm" className="me-2">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  ) : null}
                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                  Crear Principio Activo Categoria
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    </>
  );
};

export default Crear;
