import React, { useEffect } from "react";
import { getLab, resetLaboratorioFlag } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from "react-router-dom";

// Importaciones de reacciones
import {  Container,  Row,  Col,  Card,  CardBody,  CardHeader,  Alert, } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import EditarLaboratorio from "./EditarLaboratorio";

const LaboratorioEditar = () => {
  console.log("LaboratorioEditar rendered");
  // Obtiene el ID desde la URL
  const { id } = useParams();
  console.log("id desde la URL:", id);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("useEffect de LaboratorioEditar se ejecut ");
    dispatch(getLab(id));
  }, [dispatch, id]);

  const selectLayoutState = (state) => state;
  const laboratorioPageData = createSelector(selectLayoutState, (state) => ({
    laboratorio: state.Laboratorios.laboratorio,
    error: state.Laboratorios.error,
    loading: state.Laboratorios.loading,
    errorMsg: state.Laboratorios.errorMsg,
    colorMsg: state.Laboratorios.colorMsg,
    exito: state.Laboratorios.exito,
  }));

  const { laboratorio, exito, error, loading, errorMsg, colorMsg } =
    useSelector(laboratorioPageData);

  console.log("laboratorio:", laboratorio);
  console.log("exito:", exito);
  console.log("error:", error);
  console.log("loading:", loading);
  console.log("errorMsg:", errorMsg);
  console.log("colorMsg:", colorMsg);

  useEffect(() => {
    console.log("useEffect de LaboratorioEditar para resetear flag se ejecut ");
    if (errorMsg) {
      setTimeout(() => {
        console.log("setTimeout se ejecut ");
        dispatch(resetLaboratorioFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Editar Laboratorio"
            pageTitle="Listar Laboratorio"
            url="/laboratorios"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  {errorMsg && errorMsg ? (
                    <Alert color={colorMsg}> {error} </Alert>
                  ) : null}
                </CardHeader>
                <CardBody>
                  {exito && (
                    <EditarLaboratorio laboratorio={laboratorio}  />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LaboratorioEditar;
