import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Table,
} from "reactstrap";

const Solicitud = ({ solicitud }) => {
  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardHeader>
            <h6 className="card-title fw-semibold mb-0">
              Información de la Solicitud
            </h6>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <Table className="table-borderless align-middle mb-0">
                <tbody>
                  <tr>
                    <td className="fw-medium">Detalle</td>
                    <td id="d-date">{solicitud.detalle}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Diágnostico</td>
                    <td id="d-date">{solicitud.diagnostico}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Tipo</td>
                    <td id="d-date">{solicitud.tipo}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Solicitud;
