import React, { useEffect } from "react";
import { getPlanilla, resetPlanillasFlag } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from "react-router-dom";

// Importaciones de reacciones
import {  Container,  Row,  Col,  Card,  CardBody,  CardHeader,  Alert, } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Editar from "./Editar";

const PlanillaEstrategicaEditar = () => {
  console.log("Planilla Estrategica Editar rendered");
  // Obtiene el ID desde la URL
  const { id } = useParams();
  console.log("id desde la URL:", id);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("useEffect de get Planilla Estrategica se ejecut ");
    dispatch(getPlanilla(id));
  }, [dispatch, id]);

  const selectLayoutState = (state) => state;

  const pageData = createSelector(selectLayoutState, (state) => ({
    planilla: state.PlanillaEstrategica.planilla,
    error: state.PlanillaEstrategica.error,
    loading: state.PlanillaEstrategica.loading,
    errorMsg: state.PlanillaEstrategica.errorMsg,
    colorMsg: state.PlanillaEstrategica.colorMsg,
    exito: state.PlanillaEstrategica.exito,
  }));

  // Inside your component
  const { planilla, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  console.log("planillas:", planilla);
  console.log("error:", error);
  console.log("loading:", loading);
  console.log("errorMsg:", errorMsg);
  console.log("colorMsg:", colorMsg);
  console.log("exito:", exito);

    // Obtener los laboratorios
    const selectLabsState = (state) => state.Laboratorios;
    const labsPageData = createSelector(selectLabsState, (state) => state);
    // Inside your component
    const { laboratorios } = useSelector(labsPageData);
  
    console.log("laboratorios:", laboratorios[0]);
  
    // Obtener los principios activos
    const selectPrincActState = (state) => state.PrincipiosActivos;
    const princActPageData = createSelector(
      selectPrincActState,
      (state) => state
    );
    // Inside your component
    const { principiosActivos } = useSelector(princActPageData);
  
    console.log("principiosActivos:", principiosActivos[0]);

  useEffect(() => {
    console.log("useEffect de LaboratorioEditar para resetear flag se ejecut ");
    if (errorMsg) {
      setTimeout(() => {
        console.log("setTimeout se ejecut ");
        dispatch(resetPlanillasFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Editar Planilla Estratégica"
            pageTitle="Listar  Planillas Estratégicas"
            url="/planilla-estrategica"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">

                  {errorMsg && errorMsg ? (
                    <Alert color={colorMsg}> {error} </Alert>
                  ) : null}
                  
                </CardHeader>
                <CardBody>
                  {exito && (
                    <Editar planilla={planilla}  />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PlanillaEstrategicaEditar;
