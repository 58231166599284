import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  servicios: [],
  servicio: {},
  error: "", // for error message
  loading: false,
  errorMsg: false, // for error
  colorMsg: "",
  exito: false,
};

const serviciosdeentregasSlice = createSlice({
  name: "serviciosdeentrega",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "danger";
    },
    serviciosSuccess(state, action) {
      state.servicios = action.payload.data;
      state.loading = false;
      state.errorMsg = false;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    servicioSuccess(state, action) {
      state.servicio = action.payload.data;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "success";
      state.error = action.payload.message;
      state.exito = true;
    },
    servicioDeleteSuccess(state, action) {
      state.error = action.payload.message;
      state.loading = true;
      state.errorMsg = true;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    reset_servicio_flag(state) {
      state.success = false;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
      state.colorMsg = "";
    },
  },
});

export const {
  apiError, serviciosSuccess, servicioSuccess, servicioDeleteSuccess, reset_servicio_flag
} = serviciosdeentregasSlice.actions;

export default serviciosdeentregasSlice.reducer;
