import React from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import logo_mh_plus_sm from "../../../assets/images/mh-sm.png";
import logo_farmabien_sm from "../../../assets/images/farmabien-sm.png";


import { Link } from "react-router-dom";

const Comentarios = ({ solicitud }) => {
  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody className="p-4">
            <h5 className="card-title mb-4">Comentarios</h5>
            <SimpleBar style={{ height: "300px" }} className="px-3 mx-n3">
              {solicitud.comentarios?.map((comentario) => (
                <div className="d-flex mb-4">
                  <div className="flex-shrink-0">
                    <img
                      src={comentario.tipo === "Enviado" ? logo_farmabien_sm : logo_mh_plus_sm }
                      alt=""
                      className="avatar-xs  me-3"
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="fs-13">
                      
                      {comentario.tipo === "Enviado" ? "Farmabien -> " : <> {solicitud.solicitante} {" ->  "} </> }
                      <small className="text-muted">
                        {comentario.creado}
                      </small>
                    </h5>
                    <p className="text-muted">
                      {comentario.mensaje}
                    </p>
                    {comentario.attachments !== "" ? (
                      <Link
                        to={comentario.adjunto}
                        target="_blank"
                        className="badge bg-primary align-middle"
                      >
                        Ver adjunto
                      </Link>
                    ) : (
                      " "
                    )}
                  </div>
                </div>
              ))}
            </SimpleBar>

            <form action="#" className="mt-3">
              <Row className="g-3">
                <Col lg={12}>
                  <Label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label mt-4"
                  >
                    Enviar comentario
                  </Label>
                  <textarea
                    className="form-control bg-light border-light"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Ingresar mensaje"
                  ></textarea>
                </Col>
                <Col lg={12} className="text-end">
                  <Link to="#" className="btn btn-success">
                    Enviar
                  </Link>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Comentarios;
