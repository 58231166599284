import React, { useEffect } from "react";
import { getPrincActivo, resetPrincipiosActivosFlag } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from "react-router-dom";

// Importaciones de reacciones
import {  Container,  Row,  Col,  Card,  CardBody,  CardHeader,  Alert, } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import EditarPrincipioActivo from "./EditarPrincipioActivo";

const PrincipioActivoEditar = () => {

  console.log("PrincipioActivoEditar rendered");

  // Obtiene el ID desde la URL
  const { id } = useParams();
  console.log("id desde la URL:", id);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("useEffect de LaboratorioEditar se ejecut ");
    dispatch(getPrincActivo(id));
  }, [dispatch, id]);

  const selectLayoutState = (state) => state;

  const principiosActivosPageData = createSelector(selectLayoutState, (state) => ({
    principioActivo: state.PrincipiosActivos.principioActivo,
    error: state.PrincipiosActivos.error,
    loading: state.PrincipiosActivos.loading,
    errorMsg: state.PrincipiosActivos.errorMsg,
    colorMsg: state.PrincipiosActivos.colorMsg,
    exito: state.PrincipiosActivos.exito,
  }));

  // Inside your component
  const { principioActivo, error, loading, errorMsg, colorMsg, exito } =
    useSelector(principiosActivosPageData);

  console.log("principiosActivos:", principioActivo);
  console.log("error:", error);
  console.log("loading:", loading);
  console.log("errorMsg:", errorMsg);
  console.log("colorMsg:", colorMsg);
  console.log("exito:", exito);

  useEffect(() => {
    console.log("useEffect de principiosActivos para resetear flag se ejecut ");
    if (errorMsg) {
      setTimeout(() => {
        console.log("setTimeout se ejecut ");
        dispatch(resetPrincipiosActivosFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);


    //OBTENER PRINCIPIOS ACTIVOS
    const selectLayoutStatePActivos = (state) => state;
    const pActivosPageData = createSelector(selectLayoutStatePActivos, (state) => ({
      principiosActivos: state.PrincipiosActivos.principiosActivos
    }));
  
    // Inside your component
    const { principiosActivos } = useSelector(pActivosPageData);

    console.log("principiosActivos:", principiosActivos);
    


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Editar Principio Activo"
            pageTitle="Listar Principios Activos"
            url="/principiosactivos"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  {errorMsg && errorMsg ? (
                    <Alert color={colorMsg}> {error} </Alert>
                  ) : null}
                </CardHeader>
                <CardBody>
                  {exito && (
                    <EditarPrincipioActivo principioActivo={principioActivo} principiosActivos={principiosActivos[0]} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrincipioActivoEditar;
