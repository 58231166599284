import React, {  useState } from "react";
import {Col, Row, Form,} from "reactstrap";
import Select from "react-select";
const FormularioDireccionEntrega = () => {
  const [selectedSingle, setSelectedSingle] = useState({ value: "2", label: "Oficina" });
  const SingleOptions = [
    { value: "1", label: "Casa" },
    { value: "2", label: "Oficina" },
  ];
  function handleSelectSingle(selectedSingle) {
    setSelectedSingle(selectedSingle);
  }
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <div className="mt-3">
          <Form action="#" method="post" className="form-steps p-3">
                    <Row lg={5} className="pt-4 pb-2 bg-success-subtle">
                      <Col>
                        <h5 className="mb-1 mb-3">Dirección de Entrega:</h5>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Select
                            value={selectedSingle}
                            onChange={() => {
                              handleSelectSingle();
                            }}
                            options={SingleOptions}
                          />
                        </div>
                      </Col>
                      <Col>
                        <button
                          type="button"
                          className="btn btn-success btn-label right ms-auto nexttab nexttab"
                        >
                          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                          Registrar
                        </button>
                      </Col>
                    </Row>
                  </Form>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export { FormularioDireccionEntrega };
