import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editSolicitud } from "../../../slices/thunks";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import TableContainer from "../../../Components/Common/TableContainer";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { format, parse, set } from "date-fns";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);

const Lista = ({
  solicitudes,
  error,
  errorMsg,
  colorMsg,
  setSolicitudesEntregar,
  setIsLoading,
}) => {

  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const toggleModal = () => setModal(!modal);

  const [files, setFiles] = useState([]);

  const columns = useMemo(
    () => [
      {
        header: "Solicitud",
        accessorKey: "identificador",
        enableColumnFilter: false,
      },
      {
        header: "Recibe",
        accessorKey: "recibe",
        enableColumnFilter: false,
      },

      {
        header: "Teléfono",
        accessorKey: "telefono",
        enableColumnFilter: false,
      },

      {
        header: "Dirección",
        accessorKey: "direccion",
        enableColumnFilter: false,
        cell: ({ row }) => {
          const direccion = row.original.direccion;
          const coordenadas = row.original.coordenadas;

          if (Array.isArray(coordenadas) && coordenadas.length === 2) {
            const [lat, lng] = coordenadas;
            const mapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;

            return (
              <a href={mapsUrl} target="_blank" rel="noopener noreferrer">
                {direccion}
              </a>
            );
          }

          return direccion;
        },
      },

      {
        header: "Distancia",
        accessorKey: "delivery.distancia",
        enableColumnFilter: false,
      },

      {
        header: "Fecha y Hora",
        accessorKey: "delivery.fecha",
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          const date = getValue();
          if (!date) return "";

          try {
            const parsedDate = parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date());
            const formattedDate = format(parsedDate, "dd/MM/yyyy HH:mm");

            return (
              <div>
                {formattedDate}
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(date);
                    // Opcional: Mostrar un tooltip o notificación de que se copió
                  }}
                  style={{
                    marginLeft: "5px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                  title="Copiar fecha original"
                >
                  📋
                </button>
              </div>
            );
            // Parsea la fecha (asumiendo que es una cadena ISO)
          } catch (error) {
            console.error("Error al formatear la fecha:", error);
            return date; // Devuelve el valor original si hay un error
          }
        },
      },

      {
        header: "Acción",
        accessorKey: "accion",
        enableColumnFilter: false,
        cell: (cell) => {
          const rowId = cell.row.original;
          return (
            <div className="d-flex gap-3">
              <button
                className="btn btn-link text-success p-0"
                onClick={() => {
                  setSelectedRowId(rowId);
                  toggleModal();
                }}
              >
                <i
                  className="mdi mdi-pencil font-size-28"
                  id={`edittooltip-${rowId.id}`}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`edittooltip-${rowId.id}`}
                >
                  Editar
                </UncontrolledTooltip>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  function crearFechaHora(fecha, hora) {
    // Asumiendo que fecha está en formato 'YYYY-MM-DD' y hora en formato 'HH:mm'
    const [year, month, day] = fecha.split("-");
    const [hours, minutes] = hora.split(":");

    // Crear la cadena ISO manualmente
    const fechaHoraFormateada = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;

    return fechaHoraFormateada;
  }

  const handleFileSubmit = (e) => {
    e.preventDefault();

    //const fechaEntrega = crearFechaHora(fecha, hora);
    // Aquí puedes manejar la lógica para procesar el archivo
    console.log("solicitud", selectedRowId);

    const now = new Date();
    const fechaEntrega = now.toISOString();

    let archivoAdjunto = null;

    if (files.length > 0) {
      const file = files[0];
      archivoAdjunto = file.getFileEncodeDataURL();

      let delivery = {
        servicioEntrega: selectedRowId.delivery.servicioEntrega,
        estatus: "Entregado",
        fecha: fechaEntrega,
        distancia: selectedRowId.delivery.distancia,
        notaEntrega: {
          identificador: selectedRowId.delivery.notaEntrega.identificador,
          cantidadContillones:
            selectedRowId.delivery.notaEntrega.cantidadContillones,
          montoTotalDelivery:
            selectedRowId.delivery.notaEntrega.montoTotalDelivery,
          fraccionTotalDelivery:
            selectedRowId.delivery.notaEntrega.fraccionTotalDelivery,
        },
        reciboEntrega: archivoAdjunto,
      };

      // Actualizar solicitud.delivery
      const nuevaSolicitud = {
        ...selectedRowId,
        porcentaje_avance: 99,
        estatus: "Entregado",
        delivery: delivery,
      };

      console.log("nuevaSolicitud:", nuevaSolicitud);

      try {
        dispatch(editSolicitud(nuevaSolicitud));
        setIsLoading(true);

      } catch (error) {
        console.error("Error al actualizar solicitud:", error);
      }

      toggleModal();
    }
  };

  return (
    <React.Fragment>
      {errorMsg && errorMsg ? <Alert color={colorMsg}> {error} </Alert> : null}

      {solicitudes.length > 0 ? (
         <button
         id="solicitudesEntregadas"
         className="btn btn-success float-end mt-2"
         onClick={() => {
           console.log("Generar Camino de Entregas optimo:");
           setSolicitudesEntregar(true);
         }}
       >
         Generar Camino Óptimo de Entregas
       </button>
      ): null}
       

      <TableContainer
        columns={columns || []}
        data={solicitudes || []}
        isGlobalFilter={true}
        customPageSize={1000}
        SearchPlaceholder="Buscar..."
        divClass="table-responsive"
        tableClass="align-middle table-nowrap"
        theadClass="table-light text-muted"
        enableRowSelection={true}
        enableMultiRowSelection={true}
      />

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Editar Solicitud</ModalHeader>
        <Form onSubmit={handleFileSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="file">Seleccionar Archivo</Label>
              <FilePond
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={false}
                maxFiles={1}
                name="files"
                required={true}
                labelIdle='Arrastra tus archivos o <span class="filepond--label-action">buscar en tu equipo</span>'
                className="filepond filepond-input-multiple"
                allowFileEncode={true}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Guardar
            </Button>{" "}
            <Button color="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export { Lista };
