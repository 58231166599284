import React, { useEffect } from "react";
import { getPrincipioActivoCategoria, resetPrincipiosActivosCategoriaFlag } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from "react-router-dom";

// Importaciones de reacciones
import {  Container,  Row,  Col,  Card,  CardBody,  CardHeader,  Alert, } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Editar from "./Editar";


const PrincipioActivoCategoriaEditar = () => {

  // Obtiene el ID desde la URL
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPrincipioActivoCategoria(id));
  }, [dispatch, id]);

  const selectLayoutState = (state) => state;

  const principiosActivosPageData = createSelector(selectLayoutState, (state) => ({
    principioActivoCategoria: state.PrincipiosActivosCategorias.principioActivoCategoria,
    error: state.PrincipiosActivosCategorias.error,
    loading: state.PrincipiosActivosCategorias.loading,
    errorMsg: state.PrincipiosActivosCategorias.errorMsg,
    colorMsg: state.PrincipiosActivosCategorias.colorMsg,
    exito: state.PrincipiosActivosCategorias.exito,
  }));

  // Inside your component
  const { principioActivoCategoria, error, loading, errorMsg, colorMsg, exito } =
    useSelector(principiosActivosPageData);

  console.log("principioActivoCategoria:", principioActivoCategoria);
  console.log("error:", error);
  console.log("loading:", loading);
  console.log("errorMsg:", errorMsg);
  console.log("colorMsg:", colorMsg);
  console.log("exito:", exito);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        console.log("setTimeout se ejecut ");
        dispatch(resetPrincipiosActivosCategoriaFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);


    
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Editar Principio Activo Categoria"
            pageTitle="Listar Principios Activos Categorias"
            url="/principiosactivoscategorias"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  {errorMsg && errorMsg ? (
                    <Alert color={colorMsg}> {error} </Alert>
                  ) : null}
                </CardHeader>
                <CardBody>
                  {exito && (
                    <Editar principioActivoCategoria={principioActivoCategoria} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrincipioActivoCategoriaEditar;
