import React, { useState, useEffect, useMemo } from "react";

import { Alert } from "reactstrap";
import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import TableContainer from "../../../Components/Common/TableContainer";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { format, parse } from "date-fns";

const Lista = ({
  solicitudes,
  error,
  errorMsg,
  colorMsg,
  onSolicitudesSeleccionadas,
  setSolicitudesEntregar,
}) => {
  const [selectedSolicitudes, setSelectedSolicitudes] = useState({});

  useEffect(() => {
    console.log("Estado de selección:", selectedSolicitudes);
  }, [selectedSolicitudes]);

  const getSelectedSolicitudes = () => {
    return Object.entries(selectedSolicitudes)
      .filter(([_, isSelected]) => isSelected)
      .map(([id, _]) => id);
  };

  const columns = useMemo(
    () => [
      {
        id: "selection",
        header: ({ table }) => (
          <input
            type="checkbox"
            checked={table.getIsAllRowsSelected()}
            onChange={(e) => {
              table.toggleAllRowsSelected(e.target.checked);
              const newSelectedSolicitudes = {};
              table.getRowModel().rows.forEach((row) => {
                newSelectedSolicitudes[row.original.id] = e.target.checked;
              });
              setSelectedSolicitudes(newSelectedSolicitudes);
            }}
            title="Seleccionar todas las filas"
          />
        ),
        cell: ({ row }) => (
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            onChange={(e) => {
              row.toggleSelected(e.target.checked);
              setSelectedSolicitudes((prev) => ({
                ...prev,
                [row.original.id]: e.target.checked,
              }));
            }}
          />
        ),
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Solicitud",
        accessorKey: "identificador",
        enableColumnFilter: false,
      },
      {
        header: "Recibe",
        accessorKey: "recibe",
        enableColumnFilter: false,
      },

      {
        header: "Teléfono",
        accessorKey: "telefono",
        enableColumnFilter: false,
      },

      {
        header: "Dirección",
        accessorKey: "direccion",
        enableColumnFilter: false,
        cell: ({ row }) => {
          const direccion = row.original.direccion;
          const coordenadas = row.original.coordenadas;
          
          if (Array.isArray(coordenadas) && coordenadas.length === 2) {
            const [lat, lng] = coordenadas;
            const mapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
            
            return (
              <a href={mapsUrl} target="_blank" rel="noopener noreferrer">
                {direccion}
              </a>
            );
          }
          
          return direccion;
        }
      },

      {
        header: "Distancia",
        accessorKey: "delivery.distancia",
        enableColumnFilter: false,
      },

      {
        header: "Nombre",
        accessorKey: "delivery.servicioEntrega.nombre",
        enableColumnFilter: false,
      },
      {
        header: "Apellido",
        accessorKey: "delivery.servicioEntrega.apellido",
        enableColumnFilter: false,
      },
      {
        header: "Fecha y Hora",
        accessorKey: "delivery.fecha",
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          const date = getValue();
          if (!date) return "";

          try {
            const parsedDate = parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date());
            const formattedDate = format(parsedDate, "dd/MM/yyyy HH:mm");

            return (
              <div>
                {formattedDate}
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(date);
                    // Opcional: Mostrar un tooltip o notificación de que se copió
                  }}
                  style={{
                    marginLeft: "5px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                  title="Copiar fecha original"
                >
                  📋
                </button>
              </div>
            );
            // Parsea la fecha (asumiendo que es una cadena ISO)
          } catch (error) {
            console.error("Error al formatear la fecha:", error);
            return date; // Devuelve el valor original si hay un error
          }
        },
      },

      {
        header: "Acción",
        accessorKey: "accion",
        enableColumnFilter: false,
        cell: (cell) => {
          const rowId = cell.row.original.id;
          return (
            <div className="d-flex gap-3">
              <Link to={`/solicitud/ver/${rowId}/`} className="text-success">
                <i className="mdi mdi-pencil font-size-28" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Editar
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {errorMsg && errorMsg ? <Alert color={colorMsg}> {error} </Alert> : null}

      <button
      id="solicitudesEntregadas"
        className="btn btn-success float-end mt-2"
        onClick={() => {
          const selectedIds = getSelectedSolicitudes();
          const selectedSolicitudesDetails = solicitudes.filter((sol) =>
            selectedIds.includes(sol.id.toString())
          );

          // Registrar información
          console.log("IDs de solicitudes seleccionadas:", selectedIds);
          console.log(
            "Número de solicitudes seleccionadas:",
            selectedIds.length
          );
          console.log(
            "Detalles de solicitudes seleccionadas:",
            selectedSolicitudesDetails
          );

          // Llamar a la función de callback con las solicitudes seleccionadas
          if (onSolicitudesSeleccionadas) {
            onSolicitudesSeleccionadas(selectedSolicitudesDetails);
          }

          // Llamar a la nueva función handleSubmitSolicitudesEntregadas
          if (setSolicitudesEntregar) {
            setSolicitudesEntregar(selectedSolicitudesDetails);
          }
        }}
        disabled={getSelectedSolicitudes().length === 0}
      >
        Solicitudes a Entregar
      </button>

      <TableContainer
        columns={columns || []}
        data={solicitudes || []}
        isGlobalFilter={true}
        customPageSize={1000}
        SearchPlaceholder="Buscar..."
        divClass="table-responsive"
        tableClass="align-middle table-nowrap"
        theadClass="table-light text-muted"
        enableRowSelection={true}
        enableMultiRowSelection={true}
        onRowSelectionChange={(updatedSelection) => {
          setSelectedSolicitudes(updatedSelection);
        }}
        state={{ rowSelection: selectedSolicitudes }}
      />
    </React.Fragment>
  );
};

export { Lista };
