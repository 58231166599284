import React from "react";
import {  Progress } from "reactstrap";

const PorcentajeAvance = (cell) => {
  return (
    <React.Fragment>
      <Progress
        color={cell.getValue() < 50 ? "danger" : "success"}
        value={cell.getValue()}
        className="progress-xl"
      >
        {" "}
        {cell.getValue()} %{" "}
      </Progress>
      
    </React.Fragment>
  );
};

export { PorcentajeAvance };
