import { getPrincipiosActivos, getPrincipioActivo, deletePrincipioActivo, editPrincipioActivo, createPrincipioActivo } from "../../../helpers/backend_helper";
import {  apiError, principiosActivosSuccess, principioActivoSuccess, principiosActivosDeleteSuccess, reset_principiosActivos_flag,} from "./reducer";

export const getPrincActivos = () => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getPrincipiosActivos();
    }

    var data = await response;

    if (data) {
      dispatch(principiosActivosSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};
 
export const deletePrincActivo = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = deletePrincipioActivo(id);
    }
    var data = await response;
    if (data) {
      dispatch(principiosActivosDeleteSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const editPrincActivo = (principioActivo) => async (dispatch) => {
  try {
    console.log("editPrincActivo:", principioActivo);
    
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = editPrincipioActivo(principioActivo);
    }
    var data = await response;
    if (data) {
      dispatch(principioActivoSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const createPrincActivo = (principioActivo) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = createPrincipioActivo(principioActivo);
    }
    var data = await response;  

    if (data) {
      dispatch(principioActivoSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const getPrincActivo = (id) => async (dispatch) => {
  try {
    
    console.log("getPrincActivo:", id);
    
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getPrincipioActivo(id);
    }
    var data = await response;
    console.log("getPrincActivo:", data);
    
    if (data) {
      dispatch(principioActivoSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetPrincipiosActivosFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_principiosActivos_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};

//getPrincActivos, deletePrincActivo, editPrincActivo, createPrincActivo , getPrincActivo,  resetPrincipiosActivosFlag