import React, { useEffect } from "react";
import { getUser, resetUsuarioFlag } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from "react-router-dom";

// Importaciones de reacciones
import {  Container,  Row,  Col,  Card,  CardBody,  CardHeader,  Alert, } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import EditarUsuario from "./EditarUsuario";

const UsuarioEditar = () => {
  console.log("Ingresando a UsuarioEditar");
  // Obtiene el ID desde la URL
  const { id } = useParams();
  console.log("id desde la URL:", id);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("useEffect de UsuarioEditar se ejecuto con id:", id);
    dispatch(getUser(id));
  }, [dispatch, id]);

  const selectLayoutState = (state) => state;
  const usuarioPageData = createSelector(selectLayoutState, (state) => ({
    usuario: state.Usuarios.usuario,
    error: state.Usuarios.error,
    loading: state.Usuarios.loading,
    errorMsg: state.Usuarios.errorMsg,
    colorMsg: state.Usuarios.colorMsg,
    exito: state.Usuarios.exito,
  }));
  // Inside your component
  const { usuario, exito, error, loading, errorMsg, colorMsg } = useSelector(usuarioPageData);

  console.log("Usuario:", usuario);
  console.log("exito:", exito);
  console.log("error:", error);
  console.log("loading:", loading);
  console.log("errorMsg:", errorMsg);
  console.log("colorMsg:", colorMsg);


  useEffect(() => {
    console.log("useEffect de usuarioEditar para resetear flag se ejecut ");
    if (errorMsg) {
      setTimeout(() => {
        console.log("setTimeout se ejecut ");
        dispatch(resetUsuarioFlag());
        
      }, 3000);
    }
  }, [dispatch, errorMsg]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Editar Usuario" pageTitle="Listar Usuario"  url={`/usuarios/`}/>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                {errorMsg && errorMsg ? (
                    <Alert color={colorMsg}> {error} </Alert>
                  ) : null}
                </CardHeader>
                <CardBody>

                {exito && (
                    <EditarUsuario usuario={usuario}  />
                  )}

                  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsuarioEditar;