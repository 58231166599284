import React, { useState, useEffect } from "react";
import {  Col,  Row,  Input,  Label,  Form,} from "reactstrap";
import { farmacias } from "../../../common/data";
import { now } from "lodash";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const CrearFarmacia = () => {
  
  const [files, setFiles] = useState([]);

  const [nombre, setNombre] = useState("");
  const [rif, setRif] = useState("");
  const [logo, setLogo] = useState("");
  const [contactoNombre, setContactoNombre] = useState("");
  const [contactoApellido, setContactoApellido] = useState("");
  const [contactoEmail, setContactoEmail] = useState("");
  const [contactoTelefono, setContactoTelefono] = useState("");
  const [contactoCargo, setContactoCargo] = useState("");

  const [direccionEstado, setDireccionEstado] = useState("");
  const [direccionMunicipio, setDireccionMunicipio] = useState("");
  const [direccionParroquia, setDireccionParroquia] = useState("");
  const [direccionComunidad, setDireccionComunidad] = useState("");
  const [direccionCalle, setDireccionCalle] = useState("");
  const [direccionNumeroCasa, setDireccionNumeroCasa] = useState("");

  const [creado, setCreado] = useState(now());

  const handleSubmit = (event) => {
    event.preventDefault();

    const farmacia = {
      id: farmacias.length + 1,
      nombre: nombre,
      rif: rif,
      logo: logo,
      contacto: {
        nombre: contactoNombre,
        apellido: contactoApellido,
        email: contactoEmail,
        telefono: contactoTelefono,
        cargo: contactoCargo,
      },
      direccion: {
        estado: direccionEstado,
        municipio: direccionMunicipio,
        parroquia: direccionParroquia,
        comunidad: direccionComunidad,
        calle: direccionCalle,
        numeroCasa: direccionNumeroCasa,
      },
      creado: creado,
    };

    farmacias.push(farmacia);

  };

  return (
    <React.Fragment>
      <h5 className="fw-semibold text-uppercase mb-3">Crear Farmacia</h5>

      <Form>
        <Row lg={12} className="p-3">
          <Col xxl={3} md={6}>
            <div>
              <Label className="form-label" htmlFor="gen-info-email-input">
                Nombre
              </Label>
            </div>
            <Input
              type="text"
              className="form-control"
              id="nombre"
              name="nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </Col>
          <Col xxl={3} md={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="gen-info-email-input">
                RIF
              </Label>
              <Input
                type="text"
                className="form-control"
                id="rif"
                value={rif}
                onChange={(e) => setRif(e.target.value)}
              />
            </div>
          </Col>

          <Col xxl={3} md={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="gen-info-email-input">
                Logo
              </Label>
              <FilePond
                    req
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    maxFiles={1}
                    name="logo"
                    required={true}
                    labelIdle="Arrastra tus archivos o <span class='filepond--label-action'>buscar en tu equipo</span>"
                    className="filepond filepond-input-multiple"
                  />
            </div>
          </Col>

          

        </Row>

        <Row lg={12} className="p-3">
          <Col xxl={3} md={4}>
            <div>
              <h3>
                Informacion de Contacto
              </h3>
            </div>
          </Col>
        </Row>

        <Row lg={12} className="p-3">
          <Col xxl={3} md={4}>
            <div>
              <Label className="form-label" htmlFor="gen-info-email-input">
                Nombre
              </Label>
            </div>
            <Input
              type="text"
              className="form-control"
              id="contacto"
              name="nombre"
              value={contactoNombre}
              onChange={(e) => setContactoNombre(e.target.value)}
            />
          </Col>
          <Col xxl={3} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="gen-info-email-input">
                Apellido
              </Label>
              <Input
                type="text"
                className="form-control"
                id="apellido"
                value={contactoApellido}
                onChange={(e) => setContactoApellido(e.target.value)}
              />
            </div>
          </Col>
          <Col xxl={3} md={4}>
            <div>
              <Label className="form-label" htmlFor="gen-info-email-input">
                Email
              </Label>
            </div>
            <Input
              type="text"
              className="form-control"
              id="email"
              name="email"
              value={contactoEmail}
              onChange={(e) => setContactoEmail(e.target.value)}
            />
          </Col>
          <Col xxl={3} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="gen-info-email-input">
                Telefono
              </Label>
              <Input
                type="text"
                className="form-control"
                id="telefono"
                name="telefono"
                value={contactoTelefono}
                onChange={(e) => setContactoTelefono(e.target.value)}
              />
            </div>
          </Col>
          <Col xxl={3} md={4}>
            <div>
              <Label className="form-label" htmlFor="gen-info-email-input">
                Cargo
              </Label>
            </div>
            <Input
              type="text"
              className="form-control"
              id="cargo"
              name="cargo"
              value={contactoCargo}
              onChange={(e) => setContactoCargo(e.target.value)}
            />
          </Col>
        </Row>

        <Row lg={12} className="p-3">
          <Col xxl={3} md={4}>
            <div>
              <h3>
                Informacion de Direccion
              </h3>
            </div>
          </Col>
        </Row>

        <Row lg={12} className="p-3">
          <Col xxl={3} md={4}>
            <div>
              <Label className="form-label" htmlFor="gen-info-email-input">
                Estado
              </Label>
            </div>
            <Input
              type="text"
              className="form-control"
              id="estado"
              name="estado"
              value={direccionEstado}
              onChange={(e) => setDireccionEstado(e.target.value)}
            />
          </Col>
          <Col xxl={3} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="gen-info-email-input">
                Municipio
              </Label>
              <Input
                type="text"
                className="form-control"
                id="municipio"
                name="municipio"
                value={direccionMunicipio}
                onChange={(e) => setDireccionMunicipio(e.target.value)}
              />
            </div>
          </Col>
          <Col xxl={3} md={4}>
            <div>
              <Label className="form-label" htmlFor="gen-info-email-input">
                Parroquia
              </Label>
            </div>
            <Input
              type="text"
              className="form-control"
              id="parroquia"
              name="parroquia"
              value={direccionParroquia}
              onChange={(e) => setDireccionParroquia(e.target.value)}
            />
          </Col>
          <Col xxl={3} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="gen-info-email-input">
                Comunidad
              </Label>
              <Input
                type="text"
                className="form-control"
                id="comunidad"
                name="comunidad"
                value={direccionComunidad}
                onChange={(e) => setDireccionComunidad(e.target.value)}
              />
            </div>
          </Col>
          <Col xxl={3} md={4}>
            <div>
              <Label className="form-label" htmlFor="gen-info-email-input">
                Calle
              </Label>
            </div>
            <Input
              type="text"
              className="form-control"
              id="calle"
              name="calle"
              value={direccionCalle}
              onChange={(e) => setDireccionCalle(e.target.value)}
            />
          </Col>
          <Col xxl={3} md={4}>
            <div>
              <Label className="form-label" htmlFor="gen-info-email-input">
                Local
              </Label>
            </div>
            <Input
              type="text"
              className="form-control"
              id="calle"
              name="calle"
              value={direccionNumeroCasa}
              onChange={(e) => setDireccionNumeroCasa(e.target.value)}
            />
          </Col>
        </Row>

        <Row className="align-items-end">
          <Col lg={12}>
            <div className="mb-3 text-end">
              <button
                onClick={handleSubmit}
                type="submit"
                className="btn btn-success btn-label right ms-auto nexttab nexttab"
              >
                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                Crear Farmacia
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default CrearFarmacia;
