//Include Both Helper File with needed methods
//import { getFirebaseBackend } from "../../../helpers/_firebase_helper";
import { postJwtLogin } from "../../../helpers/fakebackend_helper";
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
} from "./reducer";

export const loginUser = (user, history) => async (dispatch) => {
  try {
    console.log("loginUser:", user);
    
    let response;

    console.log("process.env.REACT_APP_DEFAULTAUTH:", process.env.REACT_APP_DEFAULTAUTH);
    
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = postJwtLogin({
        username: user.email,
        password: user.password,
      });
    }

    var data = await response;

    console.log("loginUser:", data);
    

    if (data) {
      data.token = data.access_token
      sessionStorage.setItem("authUser", JSON.stringify(data));
      dispatch(loginSuccess(data));
      history("/dashboard");
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    sessionStorage.removeItem("authUser");

    dispatch(logoutUserSuccess(true));
    
  } catch (error) {
    dispatch(apiError(error));
  }
};


/* export const socialLogin = (type, history) => async (dispatch) => {
  try {
    let response;

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      response = fireBaseBackend.socialLoginUser(type);
    }
    //  else {
    //   response = postSocialLogin(data);
    // }

    const socialdata = await response;
    if (socialdata) {
      sessionStorage.setItem("authUser", JSON.stringify(response));
      dispatch(loginSuccess(response));
      history("/dashboard");
    }
  } catch (error) {
    dispatch(apiError(error));
  }
}; */

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
