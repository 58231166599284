import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  principiosActivosCategorias: [],
  principioActivoCategoria: {},
  error: "", // for error message
  loading: false,
  errorMsg: false, // for error
  colorMsg: "",
  exito: false,
};

const principiosActivosCategoriasSlice = createSlice({
  name: "principiosActivosCategorias",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "danger";
    },
    principiosActivosCategoriasSuccess(state, action) {
      state.principiosActivosCategorias = action.payload.data;
      state.loading = false;
      state.errorMsg = false;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    }, 
    principioActivoCategoriaSuccess(state, action) {
      state.principioActivoCategoria = action.payload.data;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "success";
      state.error = action.payload.message;
      state.exito = true;
    },
    principiosActivosCategoriaDeleteSuccess(state, action) {
      state.error = action.payload.message;
      state.loading = true;
      state.errorMsg = true;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    reset_principiosActivosCategoria_flag(state) {
      state.success = false;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
      state.colorMsg = "";
    },
  },
});

export const {
  apiError,
  principiosActivosCategoriasSuccess,
  principioActivoCategoriaSuccess,
  principiosActivosCategoriaDeleteSuccess,
  reset_principiosActivosCategoria_flag,
} = principiosActivosCategoriasSlice.actions;

export default principiosActivosCategoriasSlice.reducer;
