import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
} from "reactstrap";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Autocomplete } from "@react-google-maps/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { editSolicitud } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";

// Constantes para el mapa
const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 10.0678, // Coordenadas de Barquisimeto
  lng: -69.3467,
};

const InformacionDelivery = ({ solicitud }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchBox, setSearchBox] = useState(null);
  const [map, setMap] = useState(null);
  const [autocompleteOptions, setAutocompleteOptions] = useState(null);

  const [nuevaDireccion, setNuevaDireccion] = useState({
    direccion: solicitud.direccion,
    coordenadas: solicitud.coordenadas || [10.0678, -69.3467],
  });

/*   const handlePlaceSelect = () => {
    if (searchBox) {
      const place = searchBox.getPlace();
      console.log("Lugar seleccionado:", place);

      if (place && place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        setNuevaDireccion((prev) => ({
          ...prev,
          direccion: place.formatted_address,
          coordenadas: [lat, lng],
        }));

        if (map) {
          map.panTo({ lat, lng });
          map.setZoom(17);
        }
      }
    }
  }; */

  const toggle = () => setModal(!modal);

  const handleDireccionChange = (e) => {
    const { name, value } = e.target;
    setNuevaDireccion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Función para manejar la selección de lugar
/*   const onPlaceSelected = (place) => {
    if (!place || !place.geometry || !place.geometry.location) {
      console.log("No se seleccionó ningún lugar válido");
      return;
    }

    try {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      setNuevaDireccion((prev) => ({
        ...prev,
        direccion: place.formatted_address || "",
        coordenadas: [lat, lng],
      }));

      if (map) {
        map.panTo({ lat, lng });
        map.setZoom(17);
      }
    } catch (error) {
      console.error("Error al procesar la ubicación seleccionada:", error);
      toast.error("Error al procesar la ubicación seleccionada");
    }
  }; */

  // Función para manejar clics en el mapa
  const onMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    setNuevaDireccion((prev) => ({
      ...prev,
      coordenadas: [lat, lng],
    }));

    // Obtener la dirección desde las coordenadas
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          setNuevaDireccion((prev) => ({
            ...prev,
            direccion: results[0].formatted_address,
          }));
        }
      }
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {


      const updateSolicitud = {
        ...solicitud,
        direccion: nuevaDireccion.direccion,
        coordenadas: nuevaDireccion.coordenadas,
        observaciones: "Dirección de delivery actualizada",
       
      };

      // Actualizar el estado global de la solicitud
      dispatch(editSolicitud(updateSolicitud));

      toast.success("Dirección actualizada exitosamente", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      toggle();
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error al actualizar la dirección: " + error.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardHeader>
            <h5 className="card-title mb-0">Información para el Delivery</h5>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <Table className="table-borderless align-middle mb-0">
                <tbody>
                  <tr>
                    <td className="fw-medium">Recibe</td>
                    <td id="d-date">{solicitud.recibe}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Teléfono</td>
                    <td id="d-date">{solicitud.telefono}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Dirección de Entrega</td>
                    <td id="d-date">
                      {solicitud.direccion}{" "}
                      <i
                        className="mdi mdi-pencil font-size-24"
                        id="edittooltip"
                        style={{ cursor: "pointer" }}
                        onClick={toggle}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className="fw-medium">Coordendas Google Maps</td>
                    <td id="d-date">
                      {solicitud.coordenadas ? (
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${solicitud.coordenadas[0]},${solicitud.coordenadas[1]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {`${solicitud.coordenadas[0].toFixed(
                            6
                          )}, ${solicitud.coordenadas[1].toFixed(6)}`}
                          <br />
                          (Ver en Google Maps)
                        </a>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="fw-medium">Observaciones</td>
                    <td id="d-date">{solicitud.observaciones}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <Modal isOpen={modal} toggle={toggle} size="xl">
          <Form onSubmit={handleSubmit}>
            <ModalHeader toggle={toggle}>
              Editar Dirección de Entrega
            </ModalHeader>
            <ModalBody>
              <div
                style={{
                  position: "relative",
                  zIndex: 9999,
                }}
              >
                <FormGroup className="mb-3">
                  <Label>Buscar dirección</Label>
                  <Autocomplete
                    onLoad={(autocomplete) => {
                      console.log("Autocomplete cargado");
                      if (autocomplete) {
                        setSearchBox(autocomplete);

                        try {
                          // Crear bounds específicos para Barquisimeto
                          const barquisimetoBounds =
                            new window.google.maps.LatLngBounds(
                              // SW bounds (límite inferior izquierdo)
                              new window.google.maps.LatLng(9.9673, -69.4567),
                              // NE bounds (límite superior derecho)
                              new window.google.maps.LatLng(10.3573, -69.2367)
                            );

                          // Configuración específica para Barquisimeto
                          autocomplete.setOptions({
                            componentRestrictions: { country: "ve" },
                            types: ["geocode", "establishment"],
                            bounds: barquisimetoBounds,
                            strictBounds: true, // Forzar resultados dentro de los límites
                            fields: [
                              "address_components",
                              "formatted_address",
                              "geometry",
                              "name",
                            ],
                          });

                          // Bias hacia Barquisimeto
                          const barquisimetoCenter = {
                            lat: 10.0678,
                            lng: -69.3467,
                          };

                          const circle = new window.google.maps.Circle({
                            center: barquisimetoCenter,
                            radius: 15000, // 15 km radio
                          });
                          autocomplete.setBounds(circle.getBounds());

                          console.log(
                            "Autocomplete configurado para Barquisimeto"
                          );
                        } catch (error) {
                          console.error(
                            "Error configurando autocomplete:",
                            error
                          );
                        }
                      }
                    }}
                    onPlaceChanged={() => {
                      console.log("Cambio de lugar detectado");
                      if (searchBox) {
                        const place = searchBox.getPlace();
                        console.log("Lugar seleccionado:", place);

                        if (place && place.geometry) {
                          const lat = place.geometry.location.lat();
                          const lng = place.geometry.location.lng();

                          // Verificar si el lugar está dentro de Barquisimeto
                          const barquisimetoBounds =
                            new window.google.maps.LatLngBounds(
                              new window.google.maps.LatLng(9.9673, -69.4567),
                              new window.google.maps.LatLng(10.3573, -69.2367)
                            );

                          const location = new window.google.maps.LatLng(
                            lat,
                            lng
                          );

                          if (!barquisimetoBounds.contains(location)) {
                            toast.warning(
                              "La ubicación seleccionada está fuera de Barquisimeto"
                            );
                            return;
                          }

                          console.log("Coordenadas en Barquisimeto:", {
                            lat,
                            lng,
                          });

                          setNuevaDireccion((prev) => ({
                            ...prev,
                            direccion: place.formatted_address,
                            coordenadas: [lat, lng],
                          }));

                          if (map) {
                            map.panTo({ lat, lng });
                            map.setZoom(17);
                          }
                        }
                      }
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Buscar dirección en Barquisimeto..."
                      style={{
                        width: "100%",
                        height: "38px",
                        padding: "0.375rem 0.75rem",
                        fontSize: "1rem",
                        borderRadius: "0.25rem",
                        border: "1px solid #ced4da",
                      }}
                      onChange={(e) => {
                        console.log(
                          "Buscando en Barquisimeto:",
                          e.target.value
                        );
                      }}
                      autoComplete="off"
                    />
                  </Autocomplete>
                  <small className="text-muted">
                    Escribe el nombre de un lugar o dirección y selecciona una
                    opción de la lista
                  </small>
                </FormGroup>
              </div>

              <div className="mb-3">
                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    height: "400px",
                    borderRadius: "8px",
                  }}
                  center={{
                    lat: nuevaDireccion.coordenadas[0],
                    lng: nuevaDireccion.coordenadas[1],
                  }}
                  zoom={15}
                  onClick={onMapClick}
                  onLoad={(map) => setMap(map)}
                  options={{
                    zoomControl: true,
                    streetViewControl: true,
                    mapTypeControl: true,
                    fullscreenControl: true,
                  }}
                >
                  <Marker
                    position={{
                      lat: nuevaDireccion.coordenadas[0],
                      lng: nuevaDireccion.coordenadas[1],
                    }}
                    draggable={true}
                    onDragEnd={(e) => {
                      onMapClick({ latLng: e.latLng });
                    }}
                  />
                </GoogleMap>
              </div>

              <FormGroup>
                <Label for="direccion">Dirección</Label>
                <Input
                  type="textarea"
                  name="direccion"
                  id="direccion"
                  value={nuevaDireccion.direccion}
                  onChange={handleDireccionChange}
                  required
                />
              </FormGroup>

             

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Latitud</Label>
                    <Input
                      type="text"
                      value={nuevaDireccion.coordenadas[0].toFixed(6)}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Longitud</Label>
                    <Input
                      type="text"
                      value={nuevaDireccion.coordenadas[1].toFixed(6)}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Guardando...
                  </>
                ) : (
                  "Guardar Cambios"
                )}
              </Button>{" "}
              <Button color="secondary" onClick={toggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Col>
    </React.Fragment>
  );
};

export default InformacionDelivery;
