import React from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import CrearUsuario from "./CrearUsuario";

const UsuarioCrear = () => {
  document.title = "Crear Farmacia | Farme";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <BreadCrumb title="Editar Ver" pageTitle="Listar Usuario"  url={`/usuarios/`}/>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Usuario</h5>
                </CardHeader>
                <CardBody>
                <CrearUsuario/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsuarioCrear;
