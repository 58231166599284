import React, { useEffect, useState } from "react";
import { editLab } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Input, Label, Form, Spinner, Button } from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";

const EditarLaboratorio = ({ laboratorio }) => {
  const dispatch = useDispatch();

  const opcionesPresentacion = ["A", "B", "C"];
  const opcionesFarmacologica = ["A", "B", "C", "D"];
  const optionsBoolean = [ { value: "true", label: "Si" }, { value: "false", label: "No" }, ];

  const [nombre, setNombre] = useState(laboratorio.nombre);
  const [origen, setOrigen] = useState(laboratorio.origen);
  const [transnacional, setTransnacional] = useState(laboratorio.transnacional);
  const [activo, setActivo] = useState(laboratorio.activo);
  const [calidadFarmacologica, setCalidadFarmacologica] = useState(
    laboratorio.calidadFarmacologica
  );
  const [calificacionImagen, setCalificacionImagen] = useState(
    laboratorio.calificacionImagen
  );
  const [calificacionImagenOptionSelect, setCalificacionImagenOptionSelect] =
    useState({
      value: laboratorio.calificacionImagen,
      label: laboratorio.calificacionImagen,
    });
  const [
    calidadFarmacologicaOptionSelect,
    setCalidadFarmacologicaOptionSelect,
  ] = useState({
    value: laboratorio.calidadFarmacologica,
    label: laboratorio.calidadFarmacologica,
  });
  const [transnacionalOptionSelect, setTransnacionalOptionSelect] = useState(
    transnacional ? optionsBoolean[0] : optionsBoolean[1]
  );
  
  const [activoOptionSelect, setActivoOptionSelect] = useState(
    activo ? optionsBoolean[0] : optionsBoolean[1]
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [dispatch, loading]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const updateLaboratorio = {
      id: laboratorio.id,
      nombre: nombre,
      //origen: origen,
      //transnacional: transnacional,
      activo: activo,
      calidadFarmacologica: calidadFarmacologica,
      calificacionImagen: calificacionImagen,
      creado: laboratorio.creado,
      actualizado: null,
    };

    dispatch(editLab(updateLaboratorio));
    setLoading(true);
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Row lg={12} className="p-3">
          <Col xxl={6} md={6}>
            <div>
              <Label className="form-label" htmlFor="gen-info-email-input">
                Nombre <span className="text-danger">*</span>
              </Label>
            </div>
            <Input
              required
              minLength={3}
              maxLength={50}
              type="text"
              className="form-control"
              id="nombre"
              name="nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </Col>
          {/*
          <Col xxl={6} md={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="gen-info-email-input">
                Origen <span className="text-danger">*</span>
              </Label>
              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="origen"
                name="origen"
                value={origen}
                onChange={(e) => setOrigen(e.target.value)}
              />
            </div>
          </Col>
          <Col xxl={6} md={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="transnacional">
                Transnacional <span className="text-danger">*</span>
              </Label>

              <Select
                required
                defaultValue={transnacionalOptionSelect}
                onChange={(selectedOption) => {
                  setTransnacional(selectedOption.value === "true");
                }}
                options={optionsBoolean}
                id="transnacional"
                className="js-example-basic-single mb-0"
                name="transnacional"
              />
            </div>
          </Col>
*/}
          <Col xxl={6} md={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="activo">
                Activo <span className="text-danger">*</span>
              </Label>
              <Select
                defaultValue={activoOptionSelect}
                onChange={(selectedOption) => {
                  setActivo(selectedOption.value === "true");
                }}
                options={optionsBoolean}
                required
                id="activo"
                className="js-example-basic-single mb-0"
                name="activo"
              />
            </div>
          </Col>
          <Col xxl={6} md={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="calidad-farmacologica">
                Calidad Farmacológica <span className="text-danger">*</span>
              </Label>
              <Select
                defaultValue={calidadFarmacologicaOptionSelect}
                onChange={(selectedOption) => {
                  setCalidadFarmacologica(selectedOption.value);
                }}
                options={opcionesFarmacologica.map((item) => ({
                  value: item,
                  label: item,
                }))}
                required
                id="farmacologica"
                className="js-example-basic-single mb-0"
                name="farmacologia"
              />
            </div>
          </Col>
          <Col xxl={6} md={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="calidad-presentacion">
                Calidad Presentación <span className="text-danger">*</span>
              </Label>
              <Select
                defaultValue={calificacionImagenOptionSelect}
                onChange={(selectedOption) => {
                  setCalificacionImagen(selectedOption.value);
                }}
                options={opcionesPresentacion.map((item) => ({
                  value: item,
                  label: item,
                }))}
                required
                id="choices-single-default"
                className="js-example-basic-single mb-0"
                name="presentacion"
              />
            </div>
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col lg={6}>
            <div className="mt-4 mb-3">
              <Link to="/laboratorios">
                <Button
                  
                  className="btn btn-info btn-label left ms-auto nexttab nexttab"
                >
                  
                  <i className="ri-arrow-left-line label-icon align-middle fs-16 ms-2"></i>
                  Cancelar
                </Button>
              </Link>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mt-4 mb-3 text-end">
              <button
                type="submit"
                className="btn btn-success btn-label right ms-auto nexttab nexttab"
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    Loading...{" "}
                  </Spinner>
                ) : null}
                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                Actualizar Laboratorio
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default EditarLaboratorio;
