import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isFaqs, setIsFaqs] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }

    if (iscurrentState !== "Faqs") {
      setIsFaqs(false);
    }


  }, [
    history,
    iscurrentState,
    isDashboard,
    isFaqs
    
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
   /* {
      id: "dashboard",
      label: "Inicio",
      icon: " ri-apps-line",
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },*/

    {
      id: "solicitudes",
      label: "Solicitudes",
      icon: "ri-play-list-add-line",
      link: "/solicitudes",
      stateVariables: isFaqs,
      click: function (e) {
        e.preventDefault();
        // setIsFaqs(!isFaqs);
        setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
    },
    {
      id: "Laboratorios",
      label: "Laboratorios",
      icon: "ri-play-list-add-line",
      link: "/laboratorios/",
      stateVariables: isFaqs,
      click: function (e) {
        e.preventDefault();
        // setIsFaqs(!isFaqs);
        //setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
    },
    {
      id: "PrincipiosActivos",
      label: "Principios Activos",
      icon: "ri-play-list-add-line",
      link: "/principiosactivos/",
      stateVariables: isFaqs,
      click: function (e) {
        e.preventDefault();
        // setIsFaqs(!isFaqs);
        //setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
    },

    /*{
      id: "clientes",
      label: "Clientes",
      icon: "ri-play-list-add-line",
      link: "/clientes",
      stateVariables: isFaqs,
      click: function (e) {
        e.preventDefault();
        // setIsFaqs(!isFaqs);
        //setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
    },

        {
      id: "servicioentrega",
      label: "Servicio de Entrega",
      icon: "ri-play-list-add-line",
      link: "/servicio-de-entrega",
      stateVariables: isFaqs,
      click: function (e) {
        e.preventDefault();
        // setIsFaqs(!isFaqs);
        //setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
    },
 
    {
      id: "farmacias",
      label: "Farmacias",
      icon: "ri-play-list-add-line",
      link: "/farmacias",
      stateVariables: isFaqs,
      click: function (e) {
        e.preventDefault();
        // setIsFaqs(!isFaqs);
        //setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
    }, */

    {
      id: "delivery",
      label: "Delivery",
      icon: "ri-play-list-add-line",
      link: "/delivery",
      //stateVariables: isFaqs,
      click: function (e) {
        e.preventDefault();
        // setIsFaqs(!isFaqs);
        //setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
    },

    {
      id: "motorizado",
      label: "Motorizado",
      icon: "ri-play-list-add-line",
      link: "/motorizado",
      //stateVariables: isFaqs,
      click: function (e) {
        e.preventDefault();
        // setIsFaqs(!isFaqs);
        //setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
    },

    {
      id: "planillaestrategica",
      label: "Planilla Estratégica",
      icon: "ri-play-list-add-line",
      link: "/planilla-estrategica",
      stateVariables: isFaqs,
      click: function (e) {
        e.preventDefault();
        // setIsFaqs(!isFaqs);
        //setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
    },
    
    {
      id: "usuarios",
      label: "Usuarios",
      icon: "ri-play-list-add-line",
      link: "/usuarios",
      stateVariables: isFaqs,
      click: function (e) {
        e.preventDefault();
        // setIsFaqs(!isFaqs);
        //setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
    },

    
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
