import { getsolicitudes, getsolicitud, deletesolicitud, editsolicitud, createsolicitud, medicamentos, rutaOptima, solicitudesEntregar} from "../../../helpers/backend_helper";
import { apiError, solicitudesSuccess, solicitudSuccess, solicitudDeleteSuccess, medicamentosSuccess, reset_solicitud_flag, rutaSuccess } from "./reducer";

/**
 * Thunk para obtener la lista de servicios de entrega de la API.
 * Si la variable de entorno REACT_APP_DEFAULTAUTH es igual a "jwt", se utiliza la función getPlanillasDeEntregas para obtener la lista de servicios de entrega.
 * De lo contrario, se devuelve un error.
 *
 * @function
 * @returns {Function} Thunk que devuelve una promesa que se resuelve con la lista de servicios de entrega.
 */
export const getSolicitudes = (query) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getsolicitudes(query);
    }

    var data = await response;

    if (data) {
      dispatch(solicitudesSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

/**
 * Thunk para eliminar un servicio de entrega por su ID.
 *
 * @function
 * @param {string} id El ID del servicio de entrega a eliminar.
 * @returns {Promise} Promesa que se resuelve con el servicio de entrega eliminado.
 */
export const deleteSolicitud = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = deletesolicitud(id);
    }
    var data = await response;
    if (data) {
      dispatch(solicitudDeleteSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

/**
 * Thunk para editar un servicio de entrega.
 *
 * @function
 * @param {object} servicio El servicio de entrega a editar.
 * @returns {Promise} Promesa que se resuelve con el servicio de entrega editado.
 */
export const editSolicitud = (solicitud) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = editsolicitud(solicitud);
    }
    var data = await response;
    if (data) {
      dispatch(solicitudSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

  /**
   * Creates a new servicio de entrega
   * @param {object} servicio The servicio de entrega to be created
   * @returns {Promise} A promise that resolves with the created servicio de entrega
   */
export const createSolicitud = (solicitud) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = createsolicitud(solicitud);
    }
    var data = await response;  

    if (data) {
      dispatch(solicitudSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

  /**
   * Thunk para obtener un servicio de entrega por su ID.
   *
   * @function
   * @param {string} id El ID del servicio de entrega que se va a obtener.
   * @returns {Promise} Promesa que se resuelve con el servicio de entrega obtenido.
   */
export const getSolicitud = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getsolicitud(id);
    }
    var data = await response;
    if (data) {
      dispatch(solicitudSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

/**
 * Resets the flag for the servicios de entrega to its initial state.
 * Useful when needing to fetch the list of servicios de entrega again.
 *
 * @returns {Promise}
 */
export const resetSolicitudFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_solicitud_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};


  /**
   * Buscar medicamentos
   * @param {object} lista The servicio de entrega to be created
   * @returns {Promise} A promise that resolves with the created servicio de entrega
   */
  export const listaMedicamentos = (lista) => async (dispatch) => {
    try {
      let response;
      if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {        
        response = medicamentos(lista);
      }
      var data = await response;  
  
      if (data) {
        dispatch(medicamentosSuccess(data));
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };


/**
 * Thunk para obtener las solicitudes de entrega de un usuario.
 *
 * @function
 * @param {string} id_user El ID del usuario del que se desean obtener las solicitudes de entrega.
 * @returns {Promise} Promesa que se resuelve con las solicitudes de entrega del usuario.
 */
export const getSolicitudesEntregar = (id_user) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = solicitudesEntregar(id_user);
    }
    var data = await response;
    if (data) {
      dispatch(solicitudesSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};


export const createRutaOptima = (solicitudes) => async (dispatch) => {
  try {
    console.log("createRutaOptima called with:", solicitudes);
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = await rutaOptima(solicitudes);
    }
    console.log("API response:", response);

    if (response && response.data) {
      console.log("Dispatching rutaSuccess with:", response.data);
      dispatch(rutaSuccess(response.data));
    } else {
      console.log("No data in response");
    }
  } catch (error) {
    console.error("Error in createRutaOptima:", error);
    dispatch(apiError(error));
  }
};