import React, { useState, useEffect } from "react";
import {
  getSolicitudes,
  resetSolicitudFlag,
  editSolicitud,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { Lista } from "./Lista";

const Listar = () => {
  document.title = "Lista de Delivery | Farme";

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [solicitudesEntregar, setSolicitudesEntregar] = useState([]);
  const selectLayoutState = (state) => state;

  const pageData = createSelector(selectLayoutState, (state) => ({
    solicitudes: state.Solicitudes.solicitudes,
    error: state.Solicitudes.error,
    loading: state.Solicitudes.loading,
    errorMsg: state.Solicitudes.errorMsg,
    colorMsg: state.Solicitudes.colorMsg,
    exito: state.Solicitudes.exito,
  }));

  const { solicitudes, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);
  console.log("solicitudes:", solicitudes);
  console.log("isLoading", isLoading);

  useEffect(() => {
    if (errorMsg) {
      console.log("useEffect errorMsg is true");
      setTimeout(() => {
        dispatch(resetSolicitudFlag());
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    dispatch(getSolicitudes("query=Por%20Entregar"));
  }, [dispatch]);

  useEffect(() => {
    if (solicitudes) {
      setIsLoading(false);
    }
  }, [solicitudes]);

  useEffect(() => {
    if (solicitudesEntregar.length > 0) {
      let costoDelivery = process.env.REACT_APP_COSTO_DELIVERY;

      solicitudesEntregar.sort((a, b) => b.delivery.distancia - a.delivery.distancia);

      //console.log("solicitudesEntregar:", solicitudesEntregar );
      

      solicitudesEntregar.forEach((solicitud) => {
        //console.log("solicitudesEntregar:", solicitud);

        let delivery = {
          servicioEntrega: solicitud.delivery.servicioEntrega,
          estatus: "En Transito",
          fecha: solicitud.delivery.fecha,
          distancia: solicitud.delivery.distancia,
          notaEntrega: {
            identificador: solicitudesEntregar[0].identificador,
            cantidadContillones: solicitudesEntregar.length,
            montoTotalDelivery: solicitudesEntregar[0].delivery.distancia * parseFloat(costoDelivery),
            fraccionTotalDelivery:
            (solicitudesEntregar[0].delivery.distancia * parseFloat(costoDelivery)) / solicitudesEntregar.length,
          },
        };

        // Actualizar solicitud.delivery
        const nuevaSolicitud = {
          ...solicitud,
          porcentaje_avance: 98,
          estatus: "En Transito",
          delivery: delivery,
        };

        //console.log("nuevaSolicitud:", nuevaSolicitud);

        try {
          dispatch(editSolicitud(nuevaSolicitud));
        } catch (error) {
          console.error("Error al actualizar solicitud:", error);
        }
      });
    }
  }, [solicitudesEntregar]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <div className="d-md-flex align-items-center">
                    <h5 className="card-title mb-3 mb-md-0 flex-grow-1">
                      Delivery
                    </h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-1 flex-wrap"></div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Lista
                    solicitudes={solicitudes[0]}
                    error={error}
                    errorMsg={errorMsg}
                    colorMsg={colorMsg}
                    setSolicitudesEntregar={setSolicitudesEntregar}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Listar;
