import React from "react";
import {  Container,  Row,  Col,  Card,  CardBody,  CardHeader,  Button,} from "reactstrap";
import { ListaUsuario } from "./ListaUsuario";
import { Link } from "react-router-dom";

const UsuarioListar = () => {
  document.title = "Lista de Usuarios | Farme";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <div className="d-md-flex align-items-center">
                    <h5 className="card-title mb-3 mb-md-0 flex-grow-1">
                      Usuarios
                    </h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-1 flex-wrap">
                        <Link to="/usuarios/crear" >
                          <Button
                            color="success"
                            type="button"
                            className="add-btn"
                            data-bs-toggle="modal"
                            id="create-btn"
                            data-bs-target="#showModal"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Crear Usuario
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <ListaUsuario/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsuarioListar;
