import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();

// Laboratorios
export const getLaboratorios    = () => api.get(url.BASE_LABORATORIOS);
export const getLaboratorio     = (id) => api.get(url.BASE_LABORATORIOS + '/' + id);
export const deleteLaboratorio  = (id) => api.delete(url.BASE_LABORATORIOS + '/' + id);
export const editLaboratorio    = (laboratorio) => api.put(url.BASE_LABORATORIOS + '/' + laboratorio.id, laboratorio);
export const createLaboratorio  = (laboratorio) => api.create(url.BASE_LABORATORIOS, laboratorio);

// Principios Activos
export const getPrincipiosActivos   = () => api.get(url.BASE_PRINCIPIOSACTIVOS);
export const getPrincipioActivo     = (id) => api.get(url.BASE_PRINCIPIOSACTIVOS + '/' + id);
export const deletePrincipioActivo  = (id) => api.delete(url.BASE_PRINCIPIOSACTIVOS + '/' + id);
export const editPrincipioActivo    = (principioActivo) => api.put(url.BASE_PRINCIPIOSACTIVOS + '/' + principioActivo.id, principioActivo);
export const createPrincipioActivo  = (principioActivo) => api.create(url.BASE_PRINCIPIOSACTIVOS, principioActivo);

// Principios Activos Categorias
export const getPrincipiosActivosCategorias   = () => api.get(url.BASE_PRINCIPIOSACTIVOSCATEGORIAS);
export const getPrincipioActivoCategoria     = (id) => api.get(url.BASE_PRINCIPIOSACTIVOSCATEGORIAS + '/' + id);
export const deletePrincipioActivoCategoria  = (id) => api.delete(url.BASE_PRINCIPIOSACTIVOSCATEGORIAS + '/' + id);
export const editPrincipioActivoCategoria   = (principioActivo) => api.put(url.BASE_PRINCIPIOSACTIVOSCATEGORIAS + '/' + principioActivo.id, principioActivo);
export const createPrincipioActivoCategoria  = (principioActivo) => api.create(url.BASE_PRINCIPIOSACTIVOSCATEGORIAS, principioActivo);

// Usuarios
export const getUsuarios    = () => api.get(url.BASE_USUARIOS);
export const getUsuario     = (id) => api.get(url.BASE_USUARIOS + '/' + id);
export const deleteUsuario  = (id) => api.delete(url.BASE_USUARIOS + '/' + id);
export const editUsuario    = (usuario) => api.put(url.BASE_USUARIOS + '/' + usuario.id, usuario);
export const createUsuario  = (usuario) => api.create(url.BASE_USUARIOS, usuario);

// Servicios de Entrega
export const getServiciosDeEntregas    = () => api.get(url.BASE_SERVICIODEENTREGAS);
export const getServicioDeEntrega     = (id) => api.get(url.BASE_SERVICIODEENTREGAS + '/' + id);
export const deleteServicioDeEntrega  = (id) => api.delete(url.BASE_SERVICIODEENTREGAS + '/' + id);
export const editServicioDeEntrega    = (ServicioDeEntrega) => api.put(url.BASE_SERVICIODEENTREGAS + '/' + ServicioDeEntrega.id, ServicioDeEntrega);
export const createServicioDeEntrega  = (ServicioDeEntrega) => api.create(url.BASE_SERVICIODEENTREGAS, ServicioDeEntrega);

// Planilla Estratégica 
export const getPlanillasEstrategicas   = () => api.get(url.BASE_PLANILLAESTRATEGICA);
export const getPlanillaEstrategica     = (id) => api.get(url.BASE_PLANILLAESTRATEGICA + '/' + id);
export const deletePlanillaEstrategica  = (id) => api.delete(url.BASE_PLANILLAESTRATEGICA + '/' + id);
export const editPlanillaEstrategica    = (planillaEstrategica) => api.put(url.BASE_PLANILLAESTRATEGICA + '/' + planillaEstrategica.id, planillaEstrategica);
export const createPlanillaEstrategica  = (planillaEstrategica) => api.create(url.BASE_PLANILLAESTRATEGICA, planillaEstrategica);

// Solicitudes
export const getsolicitudes   = (query) => api.get(url.BASE_SOLICITUDES+ '/?' + query);
export const getsolicitud     = (id) => api.get(url.BASE_SOLICITUDES + '/' + id);
export const deletesolicitud  = (id) => api.delete(url.BASE_SOLICITUDES + '/' + id);
export const editsolicitud    = (solicitud) => api.put(url.BASE_SOLICITUDES + '/' + solicitud.id, solicitud);
export const createsolicitud  = (solicitud) => api.create(url.BASE_SOLICITUDES, solicitud);
export const medicamentos     = (lista) => api.create(url.BASE_SOLICITUDES + '/medicamentos/', lista);
export const rutaOptima       = (solicitudes) => api.create(url.BASE_SOLICITUDES + '/ruta-optima/', solicitudes);
export const solicitudesEntregar      = (id_user) => api.get(url.BASE_SOLICITUDES + '/solicitudes-entregar/' + id_user);
