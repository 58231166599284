import React, { useMemo, useState, useEffect } from "react";
import { Alert } from "reactstrap";
import { getUsers, deleteUser, resetUsuarioFlag } from "../../../slices/thunks";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
//import { laboratorios } from "../../../common/data";
import TableContainer from "../../../Components/Common/TableContainer";
import DeleteModal from "../../../Components/Common/DeleteModal";

const ListaUsuario = () => {
  const dispatch = useDispatch();
  const selectLayoutState = (state) => state;
  const usuarioPageData = createSelector(selectLayoutState, (state) => ({
    usuarios: state.Usuarios.usuarios,
    error: state.Usuarios.error,
    loading: state.Usuarios.loading,
    errorMsg: state.Usuarios.errorMsg,
    colorMsg: state.Usuarios.colorMsg,
  }));
  // Inside your component
  const { usuarios, error, loading, errorMsg, colorMsg } = useSelector(usuarioPageData);

  console.log("usuarios:", usuarios);
  console.log("error:", error);
  console.log("loading:", loading);
  console.log("errorMsg:", errorMsg);
  console.log("colorMsg:", colorMsg);

  useEffect(() => {
    if (errorMsg) {
      console.log("useEffect errorMsg is true");
      setTimeout(() => {
        dispatch(resetUsuarioFlag());
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    console.log("useEffect loading is true");
    //setTimeout(() => {
      dispatch(getUsers());
   // }, 2000);
  }, [dispatch, loading]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [borrarSolicitud, setBorrarSolicitud] = useState({});

  const onClickDelete = (borrar) => {
    if (borrar) {
      console.log("Deleting lab with id:", borrarSolicitud.id);
      dispatch(deleteUser(borrarSolicitud.id));
    }

    setDeleteModal(false);
  };

  const solicitudDelete = (solicitud) => {
    setBorrarSolicitud(solicitud);
    setDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        header: "Nombres",
        accessorKey: "nombre",
        enableColumnFilter: false,
      },
      {
        header: "Apellidos",
        accessorKey: "apellido",
        enableColumnFilter: false,
      },
      {
        header: "Correo Electrónico",
        accessorKey: "correo",
        enableColumnFilter: false,
      },
      {
        header: "Usuario",
        accessorKey: "usuario",
        enableColumnFilter: false,
      },
      {
        header: "Perfil",
        accessorKey: "perfil",
        enableColumnFilter: false,
      },
      {
        header: "Estatus",
        cell: (cell) => {
          return (
            <React.Fragment>
              {cell.getValue() ? (
                <span className="badge bg-success-subtle text-success text-uppercase">
                  Activo
                </span>
              ) : (
                <span className="badge bg-danger-subtle text-danger text-uppercase">
                  {" "}
                  No Activo{" "}
                </span>
              )}
            </React.Fragment>
          );
        },

        accessorKey: "activo",
        enableColumnFilter: false,
      },
      {
        header: "Acción",
        accessorKey: "accion",
        enableColumnFilter: false,
        cell: (cell) => {
          const rowId = cell.row.original.id;
          return (
            <div className="d-flex gap-3">
              

              <Link to={`/usuarios/editar/${rowId}`} className="text-success">
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Editar
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cell.row.original;
                  solicitudDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Eliminar
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {errorMsg && errorMsg ? <Alert color={colorMsg}> {error} </Alert> : null}
      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={() => {
          onClickDelete(true);
        }}
      />

      <TableContainer
        columns={columns || []}
        data={usuarios[0] || []}
        isGlobalFilter={true}
        customPageSize={5}
        SearchPlaceholder="Buscar..."
        divClass="table-responsive"
      />
    </React.Fragment>
  );
};

export { ListaUsuario };
