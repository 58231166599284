import { getPlanillasEstrategicas, getPlanillaEstrategica, deletePlanillaEstrategica, editPlanillaEstrategica, createPlanillaEstrategica } from "../../../helpers/backend_helper";
import { apiError, planillasSuccess, planillaSuccess, planillaDeleteSuccess, reset_planilla_flag } from "./reducer";

/**
 * Thunk para obtener la lista de servicios de entrega de la API.
 * Si la variable de entorno REACT_APP_DEFAULTAUTH es igual a "jwt", se utiliza la función getPlanillasDeEntregas para obtener la lista de servicios de entrega.
 * De lo contrario, se devuelve un error.
 *
 * @function
 * @returns {Function} Thunk que devuelve una promesa que se resuelve con la lista de servicios de entrega.
 */
export const getPlanillas = () => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getPlanillasEstrategicas();
    }

    var data = await response;

    if (data) {
      dispatch(planillasSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

/**
 * Thunk para eliminar un servicio de entrega por su ID.
 *
 * @function
 * @param {string} id El ID del servicio de entrega a eliminar.
 * @returns {Promise} Promesa que se resuelve con el servicio de entrega eliminado.
 */
export const deletePlanilla = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = deletePlanillaEstrategica(id);
    }
    var data = await response;
    if (data) {
      dispatch(planillaDeleteSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

/**
 * Thunk para editar un servicio de entrega.
 *
 * @function
 * @param {object} servicio El servicio de entrega a editar.
 * @returns {Promise} Promesa que se resuelve con el servicio de entrega editado.
 */
export const editPlantilla = (planilla) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = editPlanillaEstrategica(planilla);
    }
    var data = await response;
    if (data) {
      dispatch(planillaSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

  /**
   * Creates a new servicio de entrega
   * @param {object} servicio The servicio de entrega to be created
   * @returns {Promise} A promise that resolves with the created servicio de entrega
   */
export const createPlanilla = (planilla) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = createPlanillaEstrategica(planilla);
    }
    var data = await response;  

    if (data) {
      dispatch(planillaSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

  /**
   * Thunk para obtener un servicio de entrega por su ID.
   *
   * @function
   * @param {string} id El ID del servicio de entrega que se va a obtener.
   * @returns {Promise} Promesa que se resuelve con el servicio de entrega obtenido.
   */
export const getPlanilla = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getPlanillaEstrategica(id);
    }
    var data = await response;
    if (data) {
      dispatch(planillaSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

/**
 * Resets the flag for the servicios de entrega to its initial state.
 * Useful when needing to fetch the list of servicios de entrega again.
 *
 * @returns {Promise}
 */
export const resetPlanillasFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_planilla_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
