import {
  getLaboratorios,
  getLaboratorio,
  deleteLaboratorio,
  editLaboratorio,
  createLaboratorio,
} from "../../../helpers/backend_helper";
import {
  apiError,
  laboratoriosSuccess,
  laboratorioSuccess,
  laboratoriosDeleteSuccess,
  reset_laboratorio_flag,
} from "./reducer";

export const getLabs = () => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getLaboratorios();
    }

    var data = await response;

    if (data) {
      dispatch(laboratoriosSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const deleteLab = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = deleteLaboratorio(id);
    }
    var data = await response;
    if (data) {
      dispatch(laboratoriosDeleteSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const editLab = (laboratorio) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = editLaboratorio(laboratorio);
    }
    var data = await response;
    if (data) {
      dispatch(laboratorioSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const createLab = (laboratorio) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = createLaboratorio(laboratorio);
    }
    var data = await response;  

    if (data) {
      dispatch(laboratorioSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const getLab = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getLaboratorio(id);
    }
    var data = await response;
    if (data) {
      dispatch(laboratorioSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLaboratorioFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_laboratorio_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
