import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  solicitudes: [],
  solicitud: {},
  ruta: null,
  medicamentos:null,
  error: "", // for error message
  loading: false,
  errorMsg: false, // for error
  colorMsg: "",
  exito: false,
};

const solicitudSlice = createSlice({
  name: "solicitud",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "danger";
    },
    medicamentosSuccess(state, action) {
      state.medicamentos = action.payload.data;
      state.loading = false;
      state.errorMsg = false;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    solicitudesSuccess(state, action) {
      state.solicitudes = action.payload.data;
      state.loading = false;
      state.errorMsg = false;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    solicitudSuccess(state, action) {
      state.solicitud = action.payload.data;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "success";
      state.error = action.payload.message;
      state.exito = true;
    },
    solicitudDeleteSuccess(state, action) {
      state.error = action.payload.message;
      state.loading = true;
      state.errorMsg = true;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    reset_solicitud_flag(state) {
      state.success = false;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
      state.colorMsg = "";
    },

    rutaSuccess: (state, action) => {
      console.log("rutaSuccess reducer called with:", action.payload);
      state.ruta = action.payload; // Guardamos directamente el array
      console.log("New state.ruta:", state.ruta); // Log para verificar
    },
  },
});

export const {
  apiError, medicamentosSuccess, solicitudesSuccess, solicitudSuccess, solicitudDeleteSuccess, reset_solicitud_flag, rutaSuccess
} = solicitudSlice.actions;

export default solicitudSlice.reducer;
