import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Crear from "./Crear";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const PlanillaEstrategicaCrear = () => {
  document.title = "Crear Planilla Estrategica | Farme";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Crear Planilla Estrategica"
            pageTitle="Listar Planilla Estrategica"
            url="/planilla-estrategica"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Crear />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PlanillaEstrategicaCrear;
