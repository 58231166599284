import {
  getUsuarios,
  getUsuario,
  deleteUsuario,
  editUsuario,
  createUsuario,
} from "../../../helpers/backend_helper";
import {
  apiError,
  usuariosSuccess,
  usuarioSuccess,
  usuariosDeleteSuccess,
  reset_usuario_flag,
} from "./reducer";

export const getUsers = () => async (dispatch) => {
  try {

    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getUsuarios();
    }

    var data = await response;

    if (data) {
      dispatch(usuariosSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    console.log("Get User Id:", id);

    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getUsuario(id);
    }
    var data = await response;
    console.log("Get User:", data);
    
    if (data) {
      dispatch(usuarioSuccess(data));
    }
  } catch (error) {
    console.log("error usuario:", error);
    dispatch(apiError(error));
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = deleteUsuario(id);
    }
    var data = await response;
    if (data) {
      dispatch(usuariosDeleteSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const editUser = (usuario) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = editUsuario(usuario);
    }
    var data = await response;
    if (data) {
      dispatch(usuarioSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const createUser = (usuario) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = createUsuario(usuario);
    }
    var data = await response;

    if (data) {
      dispatch(usuarioSuccess(data));
    }
  } catch (error) {
    console.log("error usuario:", error);
    
    dispatch(apiError(error));
  }
};

export const resetUsuarioFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_usuario_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
