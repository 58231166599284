import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { YAxis } from "./MixedCharts";

const Grafica = () => {
    return (
        <React.Fragment>
            <Col xl={12}>
                <Card className="card-height-100">
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Gestion  Histórico</h4>
                    </CardHeader>

                    <CardBody>
                    <YAxis dataColors='["--vz-primary", "--vz-info", "--vz-success"]' />

                    </CardBody>
                </Card>
            </Col>

        </React.Fragment>
    );
};

export default Grafica;