import React from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import Crear from "./Crear";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const PrincipioActivoCategoriaCrear = () => {
  document.title = "Crear Principio Activo Categoria | Farme";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <BreadCrumb
            title="Crear Principio Activo Categoria"
            pageTitle="Listar Principios Activos Categorias"
            url="/principiosactivoscategorias"
          />

        
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Principios Activos Categoria</h5>
                </CardHeader>
                <CardBody>

                  <Crear />

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrincipioActivoCategoriaCrear;
