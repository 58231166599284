import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { editSolicitud } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  Input,
  Label,
  Button,
  Form,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
//import {  responsablesDelivery } from "../../../common/data";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);

const Factura_Y_Delivery = ({
  hideButtons,
  setShowResponsable,
  setShowdetails,
  setShowCamposInfo,
  setBtnDely,
  usuarios,
  solicitud,
}) => {
  //console.log("Factura_Y_Delivery component is rendering");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (solicitud) {
      setIsLoading(false);
    }
  }, [solicitud]);

  const [emailDelivery, setEmailDelivery] = useState("");
  const [files, setFiles] = useState([]);

  const [showDelivery, setShowDelivery] = useState(null);
  const [showFacturacion, setShowFacturacion] = useState(null);

  const [btnFactura, setBtnFactura] = useState(false);
  //Modal factura
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [facturaImageUrl, setFacturaImageUrl] = useState("");

  const FacturaModal = ({ isOpen, toggle, imageUrl }) => {
    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Factura</ModalHeader>
        <ModalBody>
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="Factura"
              style={{ width: "auto" }}
              onError={(e) => {
                console.error("Error al cargar la imagen:", e);
                e.target.onerror = null;
                e.target.src = "ruta/a/imagen/por/defecto.png"; // Opcional: imagen por defecto
              }}
            />
          ) : (
            <p>No se pudo cargar la imagen de la factura.</p>
          )}
        </ModalBody>
      </Modal>
    );
  };

  const openFacturaModal = (imageUrl) => {
    console.log("Abriendo modal con URL:", imageUrl);
    try {
      setFacturaImageUrl(imageUrl);
      setModalIsOpen(true);
    } catch (error) {
      console.error("Error al abrir el modal de la factura:", error);
      // Opcional: mostrar un mensaje de error al usuario
    }
  };

  const [fechaFacturacion, setFechaFacturacion] = useState(null);
  function handleFechaFacturacion(e) {
    setFechaFacturacion(e.target.value);
  }

  const [numeroFactura, setNumeroFactura] = useState(null);
  function handleNumeroFactura(e) {
    setNumeroFactura(e.target.value);
  }

  const [montoTotal, setMontoTotal] = useState(null);
  function handleMontoTotal(e) {
    const value = parseFloat(e.target.value);
    setMontoTotal(isNaN(value) ? null : value);
  }

  function crearFechaHora(fecha, hora) {
    // Asumiendo que fecha est���� en formato 'YYYY-MM-DD' y hora en formato 'HH:mm'
    const [year, month, day] = fecha.split("-");
    const [hours, minutes] = hora.split(":");

    // Crear la cadena ISO manualmente
    const fechaHoraFormateada = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;

    return fechaHoraFormateada;
  }

  function handleSubmitDelivery() {
    const fechaEntrega = crearFechaHora(fecha, hora);

    //console.log("fechaEntrega Delivery:", fechaEntrega);
    const delivery = responsable ? {
      servicioEntrega: responsable,
      estatus: "Por Entregar",
      fecha: fechaEntrega,
    } : null;

    const nuevaSolicitud = {
      ...solicitud,
      porcentaje_avance: 75,
      estatus: "Por Entregar",
      delivery: delivery,
    };

    //console.log("nuevaSolicitud:", (nuevaSolicitud));

    try {
      //console.log("nuevaSolicitud:", JSON.stringify(nuevaSolicitud));
      // Dispatch para actualizar solicitud con los medicamentos
      dispatch(editSolicitud(nuevaSolicitud));

      //setViewDelivery(false); //ver registro del delivery
      //setViewFacturacion(true); //ver card de facturacion
    } catch (error) {
      console.error("Error al actualizar solicitud:", error);
      // Manejar el error (por ejemplo, mostrar un mensaje al usuario)
    }
  }
  function handleSubmitFacturaion() {
    let archivoAdjunto = null;
  
    if (files.length > 0) {
      const file = files[0];
      archivoAdjunto = file.getFileEncodeDataURL();
    }
  
    let facturacion = {
      fecha: fechaFacturacion,
      numeroFactura: numeroFactura,
      montoTotal: montoTotal,
      adjunto: archivoAdjunto,
    };
  
    const nuevaSolicitud = {
      ...solicitud,
      porcentaje_avance: 95,
      facturacion: facturacion,
    };
  
    try {
      dispatch(editSolicitud(nuevaSolicitud));
    } catch (error) {
      console.error("Error al actualizar solicitud:", error);
    }
  }

  function showfactura() {
    setShowCamposInfo(false);
    setShowResponsable(true);
    setBtnFactura(true);
  }
  function showdetail() {
    setShowdetails(true);
  }

  const [responsable, setResposnsable] = useState("");

  function handleResponsale(e) {
    usuarios[0].forEach((servicio) => {
      if (servicio.id === e.target.value) {
        setResposnsable(servicio);
        //console.log("responsable:", JSON.stringify(servicio));
      }
    });
  }

  const [fecha, setFecha] = useState("");

  function handleFecha(e) {
    setFecha(e.target.value);
    //console.log("fecha:", e.target.value);
  }

  const [hora, setHora] = useState("");

  function handleHora(e) {
    setHora(e.target.value);
    //console.log("hora:", e.target.value);
  }

  useEffect(() => {
    //console.log("Cambio Solicitud UseEffect delivery", isLoading, solicitud);
    if (!isLoading && solicitud) {
      //console.log("delivery:", solicitud.delivery);
      if (solicitud.delivery) {
        // console.log("Delivery registrado");
        setShowDelivery(true);
      }
    }
  }, [solicitud, isLoading, dispatch]);

  const DeliveryTable = ({ solicitud, onEdit }) => {
    if (!solicitud || !solicitud.delivery) {
      return <p>No hay información de delivery disponible.</p>;
    }
  
    return (
      <div className="table-responsive">
        <table
          className="table table-striped"
          border="1"
          cellPadding="5"
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th scope="col">Responsable</th>
              <th>Teléfono</th>
              <th>Correo</th>
              <th>Estatus</th>
              <th>Fecha - Hora</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{`${solicitud.delivery.servicioEntrega?.nombre || ''} ${solicitud.delivery.servicioEntrega?.apellido || ''}`}</td>
              <td>{solicitud.delivery.servicioEntrega?.telefono || ''}</td>
              <td>{solicitud.delivery.servicioEntrega?.correo || ''}</td>
              <td>{solicitud.delivery.estatus || ''}</td>
              <td>{solicitud.delivery.fecha ? `${solicitud.delivery.fecha.split("T")[0]} ${
                solicitud.delivery.fecha.split("T")[1].split(".")[0]
              }` : ''}</td>
              <td>
                <Button onClick={onEdit}>
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const FacturacionTable = ({ solicitud, onEdit, onOpenFactura }) => {
    if (!solicitud || !solicitud.facturacion) {
      return <p>No hay información de facturación disponible.</p>;
    }
  
    return (
      <div className="table-responsive">
        <table
          className="table table-striped"
          border="1"
          cellPadding="5"
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th scope="col">Fecha</th>
              <th>Número de Factura</th>
              <th>Total (Bs.)</th>
              <th>Factura</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{solicitud.facturacion.fecha ? solicitud.facturacion.fecha.split("T")[0] : 'N/A'}</td>
              <td>{solicitud.facturacion.numeroFactura || 'N/A'}</td>
              <td>{solicitud.facturacion.montoTotal ? solicitud.facturacion.montoTotal.toFixed(2) : 'N/A'}</td>
              <td>
                {solicitud.facturacion.adjunto ? (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      onOpenFactura(solicitud.facturacion.adjunto);
                    }}
                  >
                    Ver Factura
                  </a>
                ) : 'No disponible'}
              </td>
              <td>
                <Button onClick={onEdit}>
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    console.log("Cambio Solicitud UseEffect Facturacion", isLoading, solicitud);
    if (!isLoading && solicitud) {
      console.log("facturacion:", solicitud.facturacion);
      if (solicitud.facturacion) {
        console.log("Facturacion registrado");
        setShowFacturacion(true);
      }
    }
  }, [solicitud, isLoading, dispatch]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  const editarSolicitudDelivery = () => {
    //console.log("Editar Solicitud");
    setShowDelivery(false);
  };

  const editarSolicitudFacturacion = () => {
    //console.log("Editar Solicitud");
    setShowFacturacion(false);
  };

  return (
    <React.Fragment>
      {!showDelivery ? (
        <Card>
          <CardBody>
            <Row className="mt-3">
              <Col>
                <h6 className="fw-semibold text-uppercase">
                  INFORMACIÓN DELIVERY
                </h6>
              </Col>
            </Row>

            <div id="farme__divTabla__delivery"> </div>

            <Form id="delivery-parte1-form" className="form-steps">
              <Row className="p-3">
                <Col>
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      htmlFor="gen-info-responsable-input"
                    >
                      Responsable
                    </Label>
                    <select
                      className="form-select"
                      id="t-status"
                      data-choices
                      data-choices-search-false
                      aria-label="Default select"
                      required
                      onChange={(e) => {
                        handleResponsale(e);
                      }}
                    >
                      <option value="">Seleccione</option>
                      {usuarios &&
                        usuarios[0]
                          .filter(
                            (persona) =>
                              persona.activo === true &&
                              persona.perfil === "Delivery"
                          )
                          .map((persona) => (
                            <option key={persona.id} value={persona.id}>
                              {persona.nombre} {persona.apellido}
                            </option>
                          ))}
                    </select>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      htmlFor="gen-info-fecha-input"
                    >
                      Fercha para la entrega
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      required
                      onChange={(e) => {
                        handleFecha(e);
                      }}
                    />
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="gen-info-hora-input">
                      Hora de la entrega
                    </Label>
                    <Input
                      required
                      type="time"
                      className="form-control"
                      id="exampleInputtime"
                      onChange={(e) => {
                        handleHora(e);
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="mb-3 mt-4 text-end">
                    {!hideButtons && (
                      <Button
                        color="success"
                        className="btn-label right"
                        onClick={() => {
                          handleSubmitDelivery();
                        }}
                      >
                        {" "}
                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{" "}
                        Registrar Delivery{" "}
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      ) : (
        <>
          <Card>
            <CardBody>
              <Row className="mt-3">
                <Col>
                  <h6 className="fw-semibold text-uppercase">
                    INFORMACIÓN DELIVERY
                  </h6>
                </Col>
              </Row>

              <DeliveryTable
                solicitud={solicitud}
                onEdit={editarSolicitudDelivery}
              />
            </CardBody>
          </Card>
        </>
      )}

      {!showFacturacion ? (
        <Card>
          <CardBody>
            <Row className="mt-3">
              <Col>
                <h6 className="fw-semibold text-uppercase">
                  INFORMACIÓN FACTURACIÓN
                </h6>
              </Col>
            </Row>

            <Form id="facturacion-form" className="form-steps">
              <Row lg={12} className="p-3">
                <Col lg={2}>
                  <div>
                    <Label
                      className="form-label"
                      htmlFor="gen-info-email-input"
                    >
                      Fecha
                    </Label>
                  </div>
                  <Input
                    type="date"
                    className="form-control"
                    id="FechaFacturacion"
                    onChange={(e) => {
                      handleFechaFacturacion(e);
                    }}
                  />
                </Col>

                <Col lg={2}>
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      htmlFor="gen-info-email-input"
                    >
                      Número de Factura
                    </Label>
                    <Input
                      type="number"
                      className="form-control"
                      id="factura"
                      onChange={(e) => {
                        handleNumeroFactura(e);
                      }}
                    />
                  </div>
                </Col>
                <Col lg={2}>
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      htmlFor="gen-info-email-input"
                    >
                      Costo Total
                    </Label>
                    <Input
                      type="number"
                      className="form-control"
                      id="total"
                      onChange={(e) => {
                        handleMontoTotal(e);
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      htmlFor="gen-info-email-input"
                    >
                      Adjuntar Factura
                    </Label>
                    <FilePond
                      files={files}
                      onupdatefiles={setFiles}
                      allowMultiple={false}
                      maxFiles={1}
                      name="files"
                      required={true}
                      labelIdle='Arrastra tus archivos o <span class="filepond--label-action">buscar en tu equipo</span>'
                      className="filepond filepond-input-multiple"
                      allowFileEncode={true}
                    />
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="mb-3 mt-4 text-end">
                    <Input
                      type="hidden"
                      className="form-control"
                      id="moneda"
                      value={"Bolívares"}
                      disabled
                    />

                    {!btnFactura && (
                      <Button
                        color="success"
                        className="btn-label right"
                        onClick={handleSubmitFacturaion}
                      >
                        {" "}
                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                        Registrar Facturación
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>

          
        </Card>
      ) : (
        <>
          <Card>
            <CardBody>
              <Row className="mt-3">
                <Col>
                  <h6 className="fw-semibold text-uppercase">
                    INFORMACIÓN FACTURACIÓN
                  </h6>
                </Col>
              </Row>

              <FacturacionTable
                solicitud={solicitud}
                onEdit={editarSolicitudFacturacion}
                onOpenFactura={openFacturaModal}
              />
            </CardBody>
          </Card>
        </>
      )}

<FacturaModal
            isOpen={modalIsOpen}
            toggle={() => setModalIsOpen(false)}
            imageUrl={facturaImageUrl}
          />
    </React.Fragment>
  );
};

export default Factura_Y_Delivery;
